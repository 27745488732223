<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>settings</title>
    <path d="M31.247 12.356l-2.459-0.535c-0.214-0.655-0.479-1.293-0.791-1.909l1.362-2.118c0.243-0.378 0.19-0.875-0.128-1.193l-3.832-3.832c-0.318-0.318-0.815-0.371-1.193-0.128l-2.118 1.362c-0.615-0.313-1.254-0.578-1.909-0.792l-0.535-2.459c-0.095-0.439-0.485-0.753-0.934-0.753h-5.418c-0.45 0-0.839 0.313-0.934 0.753l-0.535 2.459c-0.655 0.214-1.293 0.479-1.909 0.792l-2.118-1.362c-0.378-0.243-0.875-0.19-1.193 0.128l-3.832 3.832c-0.318 0.318-0.371 0.815-0.128 1.193l1.362 2.118c-0.313 0.615-0.578 1.254-0.792 1.909l-2.459 0.535c-0.439 0.096-0.753 0.485-0.753 0.934v5.418c0 0.45 0.313 0.839 0.753 0.934l2.459 0.535c0.214 0.655 0.479 1.293 0.792 1.909l-1.362 2.118c-0.243 0.378-0.19 0.875 0.128 1.193l3.832 3.832c0.318 0.318 0.815 0.371 1.193 0.128l2.118-1.362c0.615 0.313 1.254 0.578 1.909 0.791l0.535 2.459c0.095 0.439 0.485 0.753 0.934 0.753h5.418c0.45 0 0.839-0.313 0.934-0.753l0.535-2.459c0.655-0.214 1.293-0.479 1.909-0.791l2.118 1.362c0.378 0.243 0.875 0.19 1.193-0.128l3.832-3.832c0.318-0.318 0.371-0.815 0.128-1.193l-1.362-2.118c0.313-0.615 0.578-1.254 0.791-1.909l2.459-0.535c0.439-0.096 0.753-0.485 0.753-0.934v-5.418c0-0.45-0.313-0.839-0.753-0.934zM21.737 16c0 3.163-2.574 5.737-5.737 5.737s-5.737-2.574-5.737-5.737 2.574-5.737 5.737-5.737 5.737 2.574 5.737 5.737z"></path>
  </svg>
</template>

<script>
export default {
  name: "Settings"
}
</script>

<style scoped>

</style>
