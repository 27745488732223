<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>clipboard</title>
    <path d="M27.2 3.2h-6.696c-0.656-1.856-2.416-3.2-4.504-3.2s-3.848 1.344-4.504 3.2h-6.696c-1.768 0-3.2 1.432-3.2 3.2v22.4c0 1.768 1.432 3.2 3.2 3.2h22.4c1.768 0 3.2-1.432 3.2-3.2v-22.4c-0-1.768-1.432-3.2-3.2-3.2zM16 3.2c0.88 0 1.6 0.712 1.6 1.6s-0.72 1.6-1.6 1.6-1.6-0.712-1.6-1.6c0-0.888 0.72-1.6 1.6-1.6zM19.2 25.6h-11.2v-3.2h11.2v3.2zM24 19.2h-16v-3.2h16v3.2zM24 12.8h-16v-3.2h16v3.2z"></path>
  </svg>
</template>

<script>
export default {
  name: "Clipboard"
}
</script>

<style scoped>

</style>
