<template>
  <ion-header class="coast-header">
    <ion-toolbar class="coast-toolbar">
      <div class="coast-toolbar-flex">
        <Back class="back-button" @click="routeBack"></Back>
        <ion-title>
          {{ title }}
        </ion-title>
      </div>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import Back from "@/components/icons/Arrow.vue"
import "@/theme/components/coast-header.scss"

export default {
  name: "CoastHeader",
  components: {Back},
  props: {
    title: {
      type: String,
      required: true,
      default: null
    },
    hasBackButton: {
      type: Boolean,
      default: null
    }
  },
  methods: {
    routeBack() {
      if (this.hasBackButton !== null) {
        return this.$router.back()
      }

      this.$router.replace({name: 'Home'})
    }
  }
}
</script>

<style scoped>

</style>
