<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>charging-circle</title>
    <path d="M29.772 17.842c-0.933 0-1.757 0.601-2.045 1.489-1.555 4.804-6.064 8.289-11.379 8.289-6.599 0-11.968-5.369-11.968-11.969 0-5.293 3.457-9.788 8.231-11.361 0.922-0.304 1.546-1.166 1.546-2.137 0-0.688-0.329-1.335-0.886-1.74s-1.273-0.52-1.927-0.309c-6.574 2.117-11.345 8.279-11.345 15.547 0 9.015 7.334 16.349 16.348 16.349 7.239 0 13.38-4.734 15.52-11.266 0.22-0.672 0.105-1.408-0.31-1.981-0.414-0.572-1.078-0.912-1.786-0.912z"></path>
    <path d="M20.025 4.271c3.634 1.176 6.51 4.046 7.695 7.676 0.295 0.904 1.137 1.514 2.088 1.514h0.045c0.687 0 1.332-0.328 1.736-0.883s0.519-1.27 0.308-1.923c-1.603-4.985-5.538-8.928-10.518-10.542-0.66-0.214-1.384-0.099-1.945 0.309s-0.894 1.061-0.894 1.755v0.048c-0 0.932 0.601 1.759 1.486 2.046z"></path>
  </svg>
</template>

<script>
export default {
  name: "ChargingCircle"
}
</script>

<style scoped>

</style>
