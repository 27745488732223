<template>
  <ion-item class="field-wrapper" lines="none" :class="ionItemClass">
    <template v-if="label && label.length">
      <ion-label
          :for="labelId || name"
          class="coast-label"
          :class="{ required: required }"
          :position="labelPosition">
        {{ label }}
      </ion-label>
    </template>

    <div class="input-wrapper">
      <slot />

      <div v-if="errors && errors.length" class="invalid-feedback d-block">
        <span v-for="(error, index) in errors" :key="index">{{ error }}</span>
      </div>
    </div>
  </ion-item>

</template>

<script>

export default {
  inject: ['formControl'],

  props: {
    labelId: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    ionItemClass: {
      type: String,
      default: ''
    },

    labelPosition: {
      type: String,
      default: 'stacked'
    },

    name: {
      type: String,
      default: ''
    },

    required: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      errors: []
    }
  },

  created () {
    this.$subscribeTo(this.formControl.subject, (errors) => {
      this.errors = errors[this.name]
    });
  }
}
</script>
