<template>
  <form @submit.prevent="$emit('onSubmit', $event)">
    <slot></slot>
  </form>
</template>

<script>
import { Subject } from "rxjs";

export default {
  provide () {
    return {
      formControl: this
    }
  },

  created() {
    this.subject = new Subject()
  },

  methods: {
    setErrors(errors) {
      this.subject.next(errors)
    }
  }
}
</script>
