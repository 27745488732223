<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>calendar</title>
    <path d="M9.464 8.082c-0.553 0-1.002-0.449-1.002-1.002v-6.078c0-0.553 0.449-1.002 1.002-1.002s1.002 0.449 1.002 1.002v6.078c0 0.553-0.449 1.002-1.002 1.002z"></path>
    <path d="M22.536 8.082c-0.553 0-1.002-0.449-1.002-1.002v-6.078c0-0.553 0.449-1.002 1.002-1.002s1.002 0.449 1.002 1.002v6.078c0 0.553-0.449 1.002-1.002 1.002z"></path>
    <path d="M1.519 13.901v13.825c0 2.357 1.917 4.274 4.274 4.274h20.413c2.357 0 4.274-1.917 4.274-4.274v-13.825z"></path>
    <path d="M30.481 11.897v-4.584c0-2.357-1.917-4.274-4.274-4.274h-0.664v4.041c0 1.657-1.348 3.006-3.006 3.006s-3.006-1.348-3.006-3.006v-4.041h-7.061v4.041c0 1.658-1.348 3.006-3.006 3.006s-3.006-1.348-3.006-3.006v-4.041h-0.664c-2.357 0-4.274 1.917-4.274 4.274v4.584h28.961z"></path>
  </svg>
</template>

<script>
export default {
  name: "Calendar"
}
</script>

<style scoped>

</style>
