<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <path fill="#7e8aa7" d="M28.227 6.113l-12.227 12.227-12.227-12.227-3.773 3.773 16 16 16-16z"></path>
  </svg>
</template>

<script>
export default {
  name: "ChevronDown"
}
</script>

<style scoped>

</style>
