<template>
  <div class="camera-button" @click="takePhoto()">
    <ion-grid class="images-wrapper">
      <ion-row>
        <ion-col v-for="image in images" :key="image.webPath" size="3">
          <ion-item lines="none" class="image" :style="`background-image: url(${image.webPath})`"></ion-item>
        </ion-col>
        <ion-col
            class="custom-height"
            :class="images && images.length < 4 ? `button-step-${images.length}` : ''"
            :size="images && images.length < 3 ? 5 : 3"
        >
          <CirclePlus></CirclePlus>
          <div v-if="images && images.length < 3">
            {{ $t('Add photo') }}
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonItem } from '@ionic/vue'
import CirclePlus from "./icons/CirclePlus.vue"
import { Camera, CameraSource } from '@/composable/camera'
import '@/theme/components/upload-image.scss'
import {isEqual} from "lodash";

export default {
  name: "UploadImage",
  components: { IonGrid, IonRow, IonCol, IonItem, CirclePlus },

  data() {
    return {
      images: []
    }
  },

  watch: {
    images: {
      deep: true,
      handler(newVal) {
        this.$emit('input', newVal)
      }
    }
  },

  methods: {
    async takePhoto() {
      const images = await Camera.getPhoto({
        resultType: 'uri',
        source: CameraSource.Prompt,
        quality: 100
      })

      this.images.push(...images)
    }
  }
}
</script>

<style scoped>

</style>
