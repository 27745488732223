import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_Clipboard = _resolveComponent("Clipboard")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_IconPlus = _resolveComponent("IconPlus")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_WavesShape = _resolveComponent("WavesShape")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      ($setup.currentRoute !== 'Topic')
        ? (_openBlock(), _createBlock(_component_ion_tabs, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_router_outlet),
              _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_tab_button, {
                    tab: "submissions",
                    href: "/tabs/submissions"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Clipboard, { class: "icon-clipboard" }),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('Submissions')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_tab_button, {
                    class: "main-active",
                    tab: "topic",
                    href: "/tabs/topic"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.square }, null, 8, ["icon"]),
                      _createVNode(_component_IconPlus, { class: "icon-plus" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_tab_button, {
                    tab: "account",
                    href: "/tabs/account"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Settings, { class: "icon-settings" }),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('Account')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_tabs, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_router_outlet),
              _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_tab_button, {
                    tab: "submissions",
                    href: "/tabs/submissions"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Clipboard, { class: "icon-clipboard" }),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('Submissions')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_tab_button, {
                    class: "main-active",
                    tab: "topic",
                    href: "/tabs/topic"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_WavesShape, { class: "waves-tab" }),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('New Submission')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_tab_button, {
                    tab: "account",
                    href: "/tabs/account"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Settings, { class: "icon-settings" }),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('Account')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}