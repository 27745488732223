<template>
  <DefaultLayout>
    <FormControl ref="FormControl" @onSubmit="handleSignup" autocomplete="off">
      <ion-list lines="none" class="border-full">
        <ion-item lines="none" class="auth-title">
          <ion-title>
            {{ $t('Create account') }}
          </ion-title>
        </ion-item>

        <FieldWrapper name="first_name" ionItemClass="auth-input">
          <ion-input type="text" inputmode="text" v-model="form.first_name" id="first_name"
                     :placeholder="$t('First Name')" autocomplete="off" required></ion-input>
        </FieldWrapper>

        <FieldWrapper name="last_name" ionItemClass="auth-input">
          <ion-input type="text" inputmode="text" v-model="form.last_name" id="last_name"
                     :placeholder="$t('Last Name')" autocomplete="off" required></ion-input>
        </FieldWrapper>

        <FieldWrapper name="phone_number" ionItemClass="auth-input">
          <ion-input type="tel" inputmode="tel" v-model="form.phone_number" id="phone_number"
                     :placeholder="$t('Phone number')"
                     autocomplete="off" required></ion-input>
        </FieldWrapper>

        <FieldWrapper name="email" ionItemClass="auth-input">
          <ion-input type="email" inputmode="email" v-model="form.email" id="email" :placeholder="$t('E-mail')"
                     autocomplete="off" required></ion-input>
        </FieldWrapper>

        <FieldWrapper name="password" ionItemClass="auth-input password-input">
          <ion-input :type="show ? 'text' : 'password'" inputmode="password" v-model="form.password"
                     id="password" :placeholder="$t('Password')" autocomplete="off" required></ion-input>
          <ion-icon class="password-icon" :icon="show ? eyeOff : eye" @click="toggleShowPassword"></ion-icon>
        </FieldWrapper>

        <ion-item v-for="checkbox in checkboxes" :key="checkbox" class="checkbox-item">
          <ion-checkbox
              mode="md"
              slot="start"
              @update:modelValue="checkbox.isChecked = $event"
              :modelValue="checkbox.isChecked">
          </ion-checkbox>
          <p class="checkbox-label">
            <span v-if="checkbox.isRequired" class="is-required">*</span>
            <ion-text>{{ checkboxes[0].label }}</ion-text>
          </p>
        </ion-item>

        <ion-item class="checkbox-item" @click="redirectTo(checkboxes[0])">
          <p class="checkbox-label underline">
          <ion-text class="">{{` ${checkboxes[0].linkLabel}`}}</ion-text>
          </p>
        </ion-item>

        <ion-item lines="none" class="primary-button">
          <ion-button expand="block" type="submit">{{ $t('Sign up') }}</ion-button>
        </ion-item>

        <ion-item lines="none" class="sign-up-text">
          <p>{{ $t('Already have an account?') }}
            <ion-text class="text-underline" color="primary" @click="$router.push('/login')">
              {{ $t('Log in') }}
            </ion-text>
          </p>
        </ion-item>
      </ion-list>
    </FormControl>
  </DefaultLayout>
</template>

<script>
import {IonIcon, IonInput, IonItem, IonCheckbox, IonText, alertController} from '@ionic/vue'
import DefaultLayout from "@/views/layouts/default.vue"
import FieldWrapper from "@/components/FieldWrapper.vue"
import FormControl from "@/components/FormControl"
import {eye, eyeOff, logIn, personAdd,} from 'ionicons/icons'

export default {
  name: 'Signup',
  components: {FormControl, IonItem, IonInput, IonCheckbox, IonIcon, IonText, DefaultLayout, FieldWrapper},

  data() {
    return {
      logIn,
      eye,
      eyeOff,
      personAdd,
      form: {},
      show: false,
      checkboxes: [
        {
          id: 1,
          isChecked: false,
          isRequired: true,
          label: this.$t('I have read and agree to the terms and conditions, and I understand the risks involved'),
          link: 'https://www.lakehuron.ca/coast-watchers-terms-and-conditions',
          linkLabel: this.$t('Terms and conditions')
        },
      ]
    }
  },

  methods: {
    toggleShowPassword() {
      this.show = !this.show
    },

    handleSignup() {

      if (this.checkboxes.filter(it => it.isRequired && !it.isChecked).length) {
        this.presentAlert({
          header: this.$t('Required'),
          message: this.$t('The agree of the terms and conditions is required'),
          buttons: [this.$t('OK')]
        })
        return
      }

      this.$store.dispatch('auth/signUp', this.form)
          .then(() => {
            this.form = {}
            this.$refs.FormControl.setErrors({})
            this.$router.push({name: 'Login'})
          })
          .catch(async (err) => {
            const errorAlert = await alertController.create({
              header: this.$t('Failed'),
              subHeader: this.$t('Signup Failed'),
              message: this.$t('The form was not completed correctly, please try again'),
              buttons: [this.$t('OK')]
            });

            this.$refs.FormControl.setErrors(err.errors)

            await errorAlert.present()
          })
    },

    async presentAlert(options) {
      const alert = await alertController
          .create(options)
      await alert.present()
    },

    redirectTo(checkbox) {
      window.open(checkbox.link, '_blank');
    }
  }
}
</script>
