<template>
  <DefaultLayout>
    <FormControl ref="FormControl" @onSubmit="handleLogin" autocomplete="off">
      <ion-list lines="none" class="border-full">
        <ion-item lines="none" class="auth-title">
          <ion-title>
            {{ $t('Login') }}
          </ion-title>
        </ion-item>

        <FieldWrapper name="email" ionItemClass="auth-input">
          <ion-input
              type="email"
              inputmode="email"
              v-model="form.email"
              id="email"
              :placeholder="$t('E-mail')"
              autocomplete="off"
              required>
          </ion-input>
        </FieldWrapper>

        <FieldWrapper name="password" ionItemClass="auth-input password-input">
          <ion-input :type="show ? 'text' : 'password'" inputmode="password" v-model="form.password" id="password"
                     :placeholder="$t('Password')" autocomplete="off" required></ion-input>
          <ion-icon class="password-icon" :icon="show ? eyeOff : eye" @click="toggleShowPassword()"></ion-icon>
        </FieldWrapper>

        <ion-item lines="none" class="recovery-text">
          <ion-text slot="end" @click="$router.push('/recovery')">
            {{ $t('Recovery password?') }}
          </ion-text>
        </ion-item>

        <ion-item lines="none" class="primary-button">
          <ion-button expand="block" type="submit">{{ $t('Login') }}</ion-button>
        </ion-item>

        <ion-item lines="none" class="sign-up-text">
          <p>{{ $t('Don’t you have an account?') }}
            <ion-text class="text-underline" color="primary" @click="$router.push('/register')">
              {{ $t('Sign up') }}
            </ion-text>
          </p>
        </ion-item>
      </ion-list>
    </FormControl>
  </DefaultLayout>
</template>

<script>
import {IonTitle, IonItem, IonText, IonButton, IonInput, IonIcon, alertController} from '@ionic/vue'
import {logIn, eye, eyeOff, personAdd} from 'ionicons/icons'
import DefaultLayout from "@/views/layouts/default.vue"
import FieldWrapper from "@/components/FieldWrapper.vue"
import FormControl from "@/components/FormControl"


export default {
  name: 'SignIn',
  components: {FormControl, IonTitle, IonItem, IonText, IonButton, IonInput, IonIcon, DefaultLayout, FieldWrapper},

  data() {
    return {
      logIn,
      eye,
      eyeOff,
      personAdd,
      form: {},
      show: false
    }
  },

  methods: {
    toggleShowPassword() {
      this.show = !this.show
    },

    handleLogin() {
      this.$store.dispatch('auth/signIn', this.form)
        .then(() => {
          this.form = {}
          this.$refs.FormControl.setErrors({})

          this.$store.dispatch('auth/getUser').then(() => {
            this.$router.replace({name: 'Home'})
          })
        })
        .catch(async (err) => {
          const errorAlert = await alertController.create({
            header: this.$t('Failed'),
            subHeader: this.$t('Sign in Failed'),
            message: err.message,
            buttons: [this.$t('OK')]
          });

          this.$refs.FormControl.setErrors(err.errors)

          await errorAlert.present()
        })
    },

  }
}
</script>
