<template>
  <ion-page>
    <slot name="header"></slot>
    <ion-content :fullscreen="true">
      <slot></slot>
      <div class="shape-wave-wrapper">
        <WavesShape class="shape-wave"></WavesShape>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage } from '@ionic/vue';
import WavesShape from "@/components/icons/WavesShape"

export default {
  name: "default",
  components: { IonPage, WavesShape}
}
</script>

<style scoped>

</style>
