<template>
  <DefaultLayout>
    <FormControl ref="FormControl" @onSubmit="handleReset" autocomplete="off">
      <ion-list lines="none" class="border-full">
        <ion-item lines="none" class="auth-title">
          <ion-title>
            {{ $t('Reset password') }}
          </ion-title>
        </ion-item>

        <FieldWrapper name="email" ionItemClass="auth-input">
          <ion-input type="email" inputmode="email" v-model="form.email" id="email" :placeholder="$t('E-mail')"
                     autocomplete="off" required></ion-input>
        </FieldWrapper>

        <FieldWrapper name="password" ionItemClass="auth-input">
          <ion-input :type="showPassword ? 'text' : 'password'" inputmode="password" v-model="form.password"
                     id="password" :placeholder="$t('Password')" autocomplete="off" required></ion-input>
          <ion-icon class="password-icon" :icon="showPassword ? eyeOff : eye"
                    @click="toggleShow('showPassword')"></ion-icon>
        </FieldWrapper>

        <FieldWrapper name="password_confirmation" ionItemClass="auth-input password-input">
          <ion-input :type="showPasswordConfirmation ? 'text' : 'password'" inputmode="password"
                     v-model="form.password_confirmation" id="password_confirmation"
                     :placeholder="$t('Confirm Password')" autocomplete="off" required></ion-input>
          <ion-icon class="password-icon" :icon="showPasswordConfirmation ? eyeOff : eye"
                    @click="toggleShow('showPasswordConfirmation')"></ion-icon>
        </FieldWrapper>

        <ion-item lines="none" class="primary-button">
          <ion-button expand="block" type="submit">{{ $t('Reset password') }}</ion-button>
        </ion-item>
      </ion-list>
    </FormControl>
  </DefaultLayout>
</template>

<script>
import {IonTitle, IonItem, IonInput, alertController} from '@ionic/vue'
import {eye, eyeOff} from 'ionicons/icons'
import DefaultLayout from "@/views/layouts/default.vue"
import FieldWrapper from "@/components/FieldWrapper.vue"
import FormControl from "@/components/FormControl"
import ApiService from "@/services/api.service";

export default {
  name: "Reset",
  components: { IonTitle, IonItem, IonInput, DefaultLayout, FieldWrapper, FormControl },

  data() {
    return {
      eye,
      eyeOff,
      form: {},
      showPassword: false,
      showPasswordConfirmation: false
    }
  },

  mounted() {
    this.getRouteParams()
  },

  methods: {
    handleReset() {
      ApiService.post('auth/reset', this.form)
          .then(async () => {
            const successAlert = await alertController.create({
              header: this.$t('Success'),
              message: this.$t('Password reset successfully'),
              buttons: [this.$t('OK')]
            });
            this.$refs.FormControl.setErrors({})
            await successAlert.present()
                .then(() => {
                  this.$router.push({name: 'Login'})
                })
          })
          .catch(async (err) => {
            const errorAlert = await alertController.create({
              header: this.$t('Failed'),
              subHeader: this.$t('Reset Failed'),
              message: err.response.data.message,
              buttons: [this.$t('OK')]
            });
            this.$refs.FormControl.setErrors(err.response.data.errors)

            await errorAlert.present()
          })
    },

    toggleShow(variable) {
      this[variable] = !this[variable]
    },

    getRouteParams() {
      if (this.$route.query) {
        this.form = {
          ...this.$route.query
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
