<template>
  <DefaultLayout>
    <template v-slot:header>
      <CoastHeader :hasBackButton="true" :title="$t('Atmospheric Conditions')"></CoastHeader>
    </template>
    <FormControl @onSubmit="handleSubmit" autocomplete="off" ref="FormControl">
      <FieldWrapper ionItemClass="camera-item" name="files">
        <UploadImage @input="setImages"></UploadImage>
      </FieldWrapper>

      <FieldWrapper>
        <ion-text lines="none" color="primary">
          Current Date: {{ currentDatetime.date }}, {{ formatAMPM(currentDatetime.time) }}
        </ion-text>
      </FieldWrapper>

      <ion-item class="submission-text" lines="none">
        <ion-row>
          <ion-text>{{ $t('Was the observation date different?') }}</ion-text>
          <ion-text @click="toggleDatetime(true)" class="add" color="primary" v-if="!differentDate">
            {{ $t('+ Add another date') }}
          </ion-text>
          <ion-text @click="toggleDatetime(false)" class="add" color="primary" v-else>
            {{ $t('- Remove the observation date') }}
          </ion-text>
        </ion-row>
      </ion-item>

      <FieldWrapper v-if="differentDate" :label="$t('Date *')" ionItemClass="border-full" name="submitted_at">
        <ion-datetime :placeholder="$t('Select Date')" @ionChange="hideError('submitted_at')"
                      presentation="month-day-year"
                      v-model="datetime.date"></ion-datetime>
        <Calendar class="date-icon"></Calendar>
      </FieldWrapper>

      <FieldWrapper v-if="differentDate" :label="$t('Time *')" ionItemClass="border-full" name="submitted_at">
        <ion-datetime :placeholder="$t('Select Time')" @ionChange="hideError('submitted_at')" display-format="HH:mm"
                      v-model="datetime.time"></ion-datetime>
        <Time class="time-icon"></Time>
      </FieldWrapper>

      <FieldWrapper :label="$t('Visibility? *')" ionItemClass="border-full" name="visibility">
        <ion-radio-group @ionChange="hideError('visibility')" class="radio-group" v-model="form.visibility">
          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="1" slot="start"></ion-radio>
            <ion-label>{{$t('Yes')}}</ion-label>
          </ion-item>

          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="0" slot="start"></ion-radio>
            <ion-label>{{$t('No')}}</ion-label>
          </ion-item>
        </ion-radio-group>
      </FieldWrapper>

      <FieldWrapper :label="$t('Wave Height (Beaufort Scale) *')" ionItemClass="border-full" name="wave_height">
        <ion-select :placeholder="$t('Select')" @ionChange="hideError('wave_height')" interface="action-sheet"
                    v-model="form.wave_height">
          <ion-select-option :key="beaufortScale.id" :value="`${beaufortScale.id} ${beaufortScale.value}`" v-for="beaufortScale in beaufortScales">{{`${beaufortScale.id}. ${beaufortScale.value}`}}</ion-select-option>
        </ion-select>
        <ChevronDown class="chevron-down-icon"></ChevronDown>
      </FieldWrapper>

      <ion-item lines="none">
        <a href="https://9cf4491b-02a7-4038-a046-22c8be7f1bec.filesusr.com/ugd/697a03_e31c01a216a94aceae2cfc2bec46b2c2.pdf" target="_blank">Beaufort Scale guide</a>
      </ion-item>

      <FieldWrapper :label="$t('Wind Direction *')" ionItemClass="border-full" name="wind_direction">
        <ion-input :placeholder="$t('Wind Direction')" @ionChange="hideError('wind_direction')" inputmode="decimal" pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$" v-model="form.wind_direction"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Wave Direction *')" ionItemClass="border-full" name="wave_direction">
        <ion-input :placeholder="$t('Wave Direction')" @ionChange="hideError('wave_direction')" inputmode="decimal" pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$" v-model="form.wave_direction"></ion-input>
      </FieldWrapper>

      <ion-item lines="none">
        <a href="https://www.lakehuron.ca/_files/ugd/697a03_4423d1a4dc504cb7a5e21b7da185bbff.pdf" target="_blank">{{ $t('Compass guide') }}</a>
      </ion-item>

      <FieldWrapper :label="$t('Water temperature (C)')" ionItemClass="border-full" name="water_temperature">
        <ion-input :placeholder="$t('Water temperature')" inputmode="decimal" pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$"
                   v-model="form.water_temperature"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Air temperature (C)')" ionItemClass="border-full" name="air_temperature">
        <ion-input :placeholder="$t('Air temperature')" inputmode="decimal" pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$"
                   v-model="form.air_temperature"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Current Wind Speed (km/h)')" ionItemClass="border-full" name="current_wind_speed">
        <ion-input :placeholder="$t('Current Wind Speed')" inputmode="decimal" pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$"
                   v-model="form.current_wind_speed"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Max. Wind speed (km/h)')" ionItemClass="border-full" name="max_wind_speed">
        <ion-input :placeholder="$t('Max. Wind speed')" inputmode="decimal" pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$"
                   v-model="form.max_wind_speed"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Avg. Wind Speed (km/h)')" ionItemClass="border-full" name="avg_wind_speed">
        <ion-input :placeholder="$t('Avg. Wind Speed')" inputmode="decimal" pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$"
                   v-model="form.avg_wind_speed"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Notes')" ionItemClass="border-full">
        <ion-textarea :placeholder="$t('Enter any notes here...')" cols="20" rows="7"
                      v-model="form.notes"></ion-textarea>
      </FieldWrapper>
      <GoogleUserLocation
          :location="{
              longitude: form.longitude,
              latitude: form.latitude
            }"
          @userLocation="setLocationToForm">
      </GoogleUserLocation>

      <ion-item class="primary-button" lines="none">
        <ion-button expand="block" type="submit">{{ $t('Submit') }}</ion-button>
      </ion-item>
    </FormControl>
  </DefaultLayout>
</template>

<script>
  import {
    IonDatetime,
    IonItem,
    IonText,
    IonSelect,
    IonSelectOption,
    IonRadioGroup,
    IonRadio,
    IonLabel,
    IonTextarea,
    IonInput,
    IonRow
  } from '@ionic/vue'
  import DefaultLayout from "@/views/layouts/default.vue"
  import CoastHeader from "@/components/CoastHeader.vue"
  import GoogleUserLocation from "@/components/GoogleUserLocation.vue"
  import Calendar from "@/components/icons/Calendar.vue"
  import Time from "@/components/icons/Time.vue"
  import ChevronDown from "@/components/icons/ChevronDown.vue"
  import FormControl from "@/components/FormControl"
  import FieldWrapper from "@/components/FieldWrapper"
  import UploadImage from "@/components/UploadImage"
  import '@/theme/components/form.scss'
  import moment from "moment"
  import ApiService from "@/services/api.service";
  import {mapGetters} from "vuex";
  import {alertController, modalController} from "@ionic/core";
  import CongratulationModal from "@/components/CongratulationModal";
  import { Device } from '@capacitor/device';


  export default {
    name: "AtmosphericCondition",
    components: {
      IonDatetime,
      IonItem,
      IonText,
      IonSelect,
      IonSelectOption,
      IonRadioGroup,
      IonRadio,
      IonLabel,
      IonTextarea,
      IonInput,
      IonRow,
      FormControl,
      FieldWrapper,
      UploadImage,
      DefaultLayout,
      CoastHeader,
      Calendar,
      Time,
      ChevronDown,
      GoogleUserLocation
    },

    data() {
      return {
        form: {},
        currentDatetime: {
          date: `${moment(new Date()).format('MMMM')} ${moment(new Date()).format('D-Y')}`,
          time: new Date()
        },
        datetime: {
          date: null,
          time: null
        },
        beaufortScales: [
            {
              id:0,
              value: 'Glassy smooth; mirror-like'
            },
            {
              id:1,
              value: 'Ripples'
            },
            {
              id:2,
              value: 'Small short wavelets'
            },
            {
              id:3,
              value: 'Large wavelets; crest begin to break'
            },
            {
              id:4,
              value: 'Small waves; some white-caps'
            },
            {
              id:5,
              value: 'Better formed waves; many white-caps'
            },
            {
              id:6,
              value: 'Large waves; many white-caps'
            },
            {
              id:7,
              value: 'Large to very large waves; walking in wind is difficult'
            },
            {
              id:8,
              value: 'Very large waves- twigs breaking off of trees'
            },
            {
              id:9,
              value: 'High seas; wind damaging buildings'
            },
            {
              id:10,
              value: 'High seas: wind uproots trees'
            },
            {
              id:11,
              value: 'High seas; wind causes widespread damage'
            },
            {
              id:12,
              value: 'High seas; Category 1 hurricane'
            },
        ],
        differentDate: false,
        files: [],
        errors: [],
        localError: {
          "created_at": ["The created at field is required."],
          "visibility": ["The visibility field is required."],
          "wave_height": ["The wave height field is required."],
          "wind_direction": ["The wind direction field is required."],
          "wave_direction": ["The wave direction field is required."]
        }
      }
    },

    computed: {
      ...mapGetters("auth", ["user"])
    },

    methods: {
      async handleSubmit() {
        const data = new FormData()

        const formattedDates = this.formattedDates(this.dates)

        const deviceInfo = await this.getDeviceInfo();
        data.append('device', JSON.stringify(deviceInfo));

        if (this.datetime && this.datetime.time && this.datetime.date) {
          data.append('submitted_at', this.formattedDates(this.datetime))
        }

        if (this.files) {
          this.files.forEach(image => {
            data.append('files[]', image.file, image.file.name)
          })
        }

        data.append('form_type', this.$options.name)

        data.append('user_id', this.user.id)

        for (const [key, value] of Object.entries(this.form)) {
          data.append(key, value)
        }

        if (!navigator.onLine) {
          let formData = null
          if (this.datetime && this.datetime.date && this.datetime.time) {
            formData = {
              ...this.form,
              'submitted_at': this.formattedDates()
            }
          }
          const errKeys = Object.keys(this.localError)
          const formKeys = Object.keys(this.datetime && formData ? formData : this.form)
          const diff = errKeys.filter(x => !formKeys.includes(x));
          const errors = {}

          diff.map((index) => {
            errors[index] = this.localError[index]
          })

          this.errors = errors
          this.$refs.FormControl.setErrors(errors)

          if (Object.keys(errors).length) {
            const errorAlert = await alertController.create({
              header: this.$t('Failed to submit'),
              subHeader: this.$t('The data was invalid'),
              message: this.$t('Please fill all the required fields'),
              buttons: [this.$t('OK')]
            });

            await errorAlert.present()

            return
          }
        }

        try {
          await ApiService.post('/submission', data)
            .then(() => {
              this.openModal()
              this.$store.dispatch('auth/getUser')
            })

            .catch(async (err) => {
              const errorAlert = await alertController.create({
                header: this.$t('Failed'),
                subHeader: this.$t('Submission Failed'),
                message: err.response.data.message,
                buttons: [this.$t('OK')]
              });

              this.$refs.FormControl.setErrors(err.response.data.errors)
              this.errors = err.response.data.errors

              await errorAlert.present()
            })
        } catch (err) {
          const errorAlert = await alertController.create({
            header: this.$t('Failed to submit'),
            subHeader: this.$t('No Internet Connection'),
            message: this.$t('Your report will be stored and sent once you will be online'),
            buttons: [this.$t('OK')]
          });

          await errorAlert.present()
        }
      },

      formattedDates() {
        if (this.datetime && this.datetime.date && this.datetime.time) {
          const time = new Date(this.datetime.time);
          return moment(this.datetime.date)
              .set('hour', time.getHours())
              .set('minutes', time.getMinutes())
              .set('seconds', time.getSeconds())
              .toISOString()
        }
      },

      formatAMPM(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes + ' ' + ampm;
      },

      setLocationToForm(data) {
        if (data.longitude) {
          this.form.longitude = data.longitude
        }

        if (data.latitude) {
          this.form.latitude = data.latitude
        }
      },

      setImages(data) {
        this.files = [...data]
      },

      toggleDatetime(value) {
        this.differentDate = value
      },

      async openModal() {
        const modal = await modalController
          .create({
            component: CongratulationModal,
            componentProps: {
              resetForm: () => this.form = {},
            },
            cssClass: 'success',
            backdropDismiss:false
          });
        return modal.present();
      },

      hideError(key) {
        if (key === 'created_at') {
          if (this.dates[0] && this.dates[0].date && this.dates[0].time) {
            delete this.errors[key]
            this.$refs.FormControl.setErrors(this.errors)
          }
          return
        } else {
          delete this.errors[key]
          this.$refs.FormControl.setErrors(this.errors)
        }
      },
      async getDeviceInfo() {
        return await Device.getInfo();
      }
    }
  }
</script>

<style scoped>

</style>
