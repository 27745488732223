import { auth } from "./auth.store";
import { home } from "./home.store";
import { submissions } from "./submissions.store";
import {createStore} from 'vuex';
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        home,
        submissions
    },
    plugins: [createPersistedState()]
});

export default store;
