<template>
  <DefaultLayout>
    <template v-slot:header>
      <CoastHeader :hasBackButton="true" :title="$t('Algae Wash-up')"></CoastHeader>
    </template>
    <FormControl @onSubmit="handleSubmit" autocomplete="off" ref="FormControl">

      <ion-item lines="none" class="warning">
        {{ $t('WARNING: Some algae is toxic and can be harmful to humans and animals. Please no NOT touch algae unless you are wearing the proper protective equipment') }}
      </ion-item>

      <ion-item lines="none">
        <a href="https://www.lakehuron.ca/algae-field-guide" target="_blank">{{ $t('Algae Field Guide') }}</a>
      </ion-item>

      <FieldWrapper ionItemClass="camera-item" name="files">
        <UploadImage @input="setImages"></UploadImage>
      </FieldWrapper>

      <FieldWrapper>
        <ion-text lines="none" color="primary">
          Current Date: {{ currentDatetime.date }}, {{ formatAMPM(currentDatetime.time) }}
        </ion-text>
      </FieldWrapper>

      <ion-item class="submission-text" lines="none">
        <ion-row>
          <ion-text>{{ $t('Was the observation date different?') }}></ion-text>
          <ion-text @click="toggleDatetime(true)" class="add" color="primary" v-if="!differentDate">
            {{ $t('+ Add another date') }}
          </ion-text>
          <ion-text @click="toggleDatetime(false)" class="add" color="primary" v-else>
            {{ $t('- Remove the observation date') }}
          </ion-text>
        </ion-row>
      </ion-item>

      <FieldWrapper v-if="differentDate" :label="$t('Date *')" ionItemClass="border-full" name="submitted_at">
        <ion-datetime :placeholder="$t('Select Date')" @ionChange="hideError('submitted_at')"
                      presentation="month-day-year"
                      v-model="datetime.date"></ion-datetime>
        <Calendar class="date-icon"></Calendar>
      </FieldWrapper>

      <FieldWrapper v-if="differentDate" :label="$t('Time *')" ionItemClass="border-full" name="submitted_at">
        <ion-datetime :placeholder="$t('Select Time')" @ionChange="hideError('submitted_at')" display-format="HH:mm"
                      v-model="datetime.time"></ion-datetime>
        <Time class="time-icon"></Time>
      </FieldWrapper>

      <FieldWrapper :label="$t('Are there algae in the water?')" ionItemClass="border-full" name="beachTypeExists">
        <ion-radio-group class="radio-group" v-model="algaeInWater">
          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="true" slot="start"></ion-radio>
            <ion-label>{{ $t('Yes') }}</ion-label>
          </ion-item>

          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="false" slot="start"></ion-radio>
            <ion-label>{{ $t('No') }}</ion-label>
          </ion-item>
        </ion-radio-group>
      </FieldWrapper>

      <FieldWrapper v-if="algaeInWater" :label="$t('Algae in water length (m) *')" ionItemClass="border-full"
                    name="algae_in_water_length">
        <ion-input :placeholder="$t('Algae in water length')" @ionChange="hideError('algae_in_water_length')"
                   inputmode="decimal"
                   pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$"
                   v-model="form.algae_in_water_length"></ion-input>
      </FieldWrapper>

      <FieldWrapper v-if="algaeInWater" :label="$t('Algae in water width (m) *')" ionItemClass="border-full"
                    name="algae_in_water_width">
        <ion-input :placeholder="$t('Algae in water width')" @ionChange="hideError('algae_in_water_width')"
                   inputmode="decimal"
                   pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$"
                   v-model="form.algae_in_water_width"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Are there algae on beach?')" ionItemClass="border-full" name="algaeOnBeach">
        <ion-radio-group class="radio-group" v-model="algaeOnBeach">
          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="true" slot="start"></ion-radio>
            <ion-label>{{ $t('Yes') }}</ion-label>
          </ion-item>

          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="false" slot="start"></ion-radio>
            <ion-label>{{ $t('No') }}</ion-label>
          </ion-item>
        </ion-radio-group>
      </FieldWrapper>

      <FieldWrapper v-if="algaeOnBeach" :label="$t('Algae on beach length (m) *')" ionItemClass="border-full"
                    name="algae_on_beach_length">
        <ion-input :placeholder="$t('Algae on beach length')" @ionChange="hideError('algae_on_beach_length')"
                   inputmode="decimal"
                   pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$"
                   v-model="form.algae_on_beach_length"></ion-input>
      </FieldWrapper>

      <FieldWrapper v-if="algaeOnBeach" :label="$t('Algae on beach width (m) *')" ionItemClass="border-full"
                    name="algae_on_beach_width">
        <ion-input :placeholder="$t('Algae on beach width')" @ionChange="hideError('algae_on_beach_width')"
                   inputmode="decimal"
                   pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$"
                   v-model="form.algae_on_beach_width"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Algae category *')" ionItemClass="border-full" name="category">
        <ion-select :placeholder="$t('Select')" @ionChange="hideError('category')" interface="action-sheet"
                    v-model="category">
          <ion-select-option :key="i" :value="i" v-for="i in categories">
            {{ i.name }}
          </ion-select-option>
        </ion-select>
        <ChevronDown class="chevron-down-icon"></ChevronDown>
      </FieldWrapper>

      <ion-item v-if="category" lines="none">
        {{ category.description }}
      </ion-item>

      <FieldWrapper :label="$t('Algae colour *')" ionItemClass="border-full" name="colour">
        <ion-select :placeholder="$t('Select')" @ionChange="hideError('colour')" interface="action-sheet"
                    v-model="form.colour">
          <ion-select-option :key="i" :value="i" v-for="i in colours">{{ i }}</ion-select-option>
        </ion-select>
        <ChevronDown class="chevron-down-icon"></ChevronDown>
      </FieldWrapper>

      <FieldWrapper v-if="form.colour && form.colour.includes('None of the above')" :label="$t('Other colour *')"
                    ionItemClass="border-full" name="other_colour">
        <ion-input :placeholder="$t('Other')" @ionChange="hideError('other_colour')"
                   type="text"
                   v-model="form.other_colour"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Visual observation')" ionItemClass="border-full" name="visual_observation">
        <ion-select multiple="true" :placeholder="$t('Select')" @ionChange="hideError('visual_observation')"
                    interface="action-sheet"
                    v-model="form.visual_observation">
          <ion-select-option :key="i" :value="i" v-for="i in visualObservations">{{ i }}</ion-select-option>
        </ion-select>
        <ChevronDown class="chevron-down-icon"></ChevronDown>
      </FieldWrapper>

      <FieldWrapper :label="$t('Odour *')" ionItemClass="border-full" name="odour">
        <ion-select :placeholder="$t('Select')" @ionChange="hideError('odour')" interface="action-sheet"
                    v-model="form.odour">
          <ion-select-option :key="i" :value="i" v-for="i in odours">{{ i }}</ion-select-option>
        </ion-select>
        <ChevronDown class="chevron-down-icon"></ChevronDown>
      </FieldWrapper>

      <FieldWrapper v-if="form.odour && form.odour.includes('Other')" :label="$t('Other odour *')"
                    ionItemClass="border-full" name="other_odour">
        <ion-input :placeholder="$t('Other')" @ionChange="hideError('other_odour')"
                   type="text"
                   v-model="form.other_odour"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Environment *')" ionItemClass="border-full" name="environment">
        <ion-select :placeholder="$t('Select')" @ionChange="hideError('environment')" interface="action-sheet"
                    v-model="form.environment">
          <ion-select-option :key="i" :value="i" v-for="i in environments">{{ i }}</ion-select-option>
        </ion-select>
        <ChevronDown class="chevron-down-icon"></ChevronDown>
      </FieldWrapper>

      <FieldWrapper v-if="form.environment && form.environment.includes('Other')" :label="$t('Other environment *')"
                    ionItemClass="border-full" name="other_environment">
        <ion-input :placeholder="$t('Other')" @ionChange="hideError('other_environment')"
                   type="text"
                   v-model="form.other_environment"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Are the algae attached to an object? (Ex. rock) *')" ionItemClass="border-full"
                    name="attachedToObject">
        <ion-radio-group class="radio-group" v-model="attachedToObject">
          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="true" slot="start"></ion-radio>
            <ion-label>{{ $t('Yes') }}</ion-label>
          </ion-item>

          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="false" slot="start"></ion-radio>
            <ion-label>{{ $t('No') }}</ion-label>
          </ion-item>
        </ion-radio-group>
      </FieldWrapper>

      <FieldWrapper v-if="attachedToObject" :label="$t('What is the object?')" ionItemClass="border-full"
                    name="attached_object">
        <ion-input :placeholder="$t('Object')" @ionChange="hideError('attached_object')"
                   type="text"
                   v-model="form.attached_object"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Notes')" ionItemClass="border-full">
        <ion-textarea :placeholder="$t('Enter any notes here...')" cols="20" rows="7"
                      v-model="form.notes"></ion-textarea>
      </FieldWrapper>

      <GoogleUserLocation
          :location="{
              longitude: form.longitude,
              latitude: form.latitude
            }"
          @userLocation="setLocationToForm">
      </GoogleUserLocation>

      <ion-item class="primary-button" lines="none">
        <ion-button expand="block" type="submit">{{ $t('Submit') }}</ion-button>
      </ion-item>
    </FormControl>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/views/layouts/default.vue"
import CoastHeader from "@/components/CoastHeader.vue"
import FormControl from "@/components/FormControl"
import '@/theme/components/form.scss'
import {
  IonDatetime,
  IonInput,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonRow
} from '@ionic/vue'
import FieldWrapper from "@/components/FieldWrapper";
import UploadImage from "@/components/UploadImage";
import Calendar from "@/components/icons/Calendar";
import Time from "@/components/icons/Time";
import GoogleUserLocation from "@/components/GoogleUserLocation";
import moment from "moment";
import {mapGetters} from "vuex";
import {alertController, modalController} from "@ionic/core";
import ApiService from "@/services/api.service";
import CongratulationModal from "@/components/CongratulationModal";
import { Device } from '@capacitor/device';

export default {
  name: "AlgaeWashup",
  components: {
    IonDatetime,
    IonItem,
    IonText,
    IonRadioGroup,
    IonRadio,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonInput,
    IonRow,
    FormControl,
    FieldWrapper,
    UploadImage,
    DefaultLayout,
    CoastHeader,
    Calendar,
    Time,
    GoogleUserLocation
  },
  data() {
    return {
      form: {},
      currentDatetime: {
        date: `${moment(new Date()).format('MMMM')} ${moment(new Date()).format('D-Y')}`,
        time: new Date()
      },
      datetime: {
        date: null,
        time: null
      },
      algaeInWater: null,
      attachedToObject: null,
      algaeOnBeach: null,
      files: [],
      differentDate: false,
      category: null,
      categories: [
        {
          name: 'Filamentous',
          description: 'Algae that is linked together to form threads or a mesh like mat'
        },
        {
          name: 'Planktonic',
          description: 'Algae that floats in the water column causing water to become green, brown, or reddish'
        }
      ],
      colours: [
        'Bright green',
        'Olive green',
        'Red',
        'Pink',
        'Yellow',
        'Orange',
        'Light brown',
        'Dark brown',
        'Dark green',
        'Blue',
        'None of the above'
      ],
      visualObservations: [
        'Slimy',
        'Threads',
        'Glob',
        'Plant like',
        'Silky',
        'Slippery',
        'Mat formation',
        'Net formation',
        'Film',
        'Scum',
        'Foam'
      ],
      odours: [
        'No smell',
        'Septic',
        'Pigpen',
        'Musky',
        'Garlicky',
        'Grassy',
        'Other'
      ],
      environments: [
        'Shoreline',
        'Nearshore water',
        'Wetland',
        'Pond',
        'River',
        'River mouth',
        'Other'
      ],
      errors: [],
      localError: {
        "created_at": ["The created at field is required."],
        "submitted_at": ["The submitted at field is required."],
        "algae_in_water_length": ["The algae in water length field is required."],
        "algae_in_water_width": ["The algae in water width field is required."],
        "algae_on_beach_length": ["The algae on beach length field is required."],
        "algae_on_beach_width": ["The algae on beach width field is required."],
        "category": ["The Category field is required."],
        "colour": ["The Algae colour field is required."]
      }
    }
  },

  computed: {
    ...mapGetters("auth", ["user"])
  },


  methods: {
    async handleSubmit() {
      const data = new FormData()


      const deviceInfo = await this.getDeviceInfo();
      data.append('device', JSON.stringify(deviceInfo));

      if (this.datetime && this.datetime.time && this.datetime.date) {
        data.append('submitted_at', this.formattedDates(this.datetime))
      }

      if (this.files) {
        this.files.forEach(image => {
          data.append('files[]', image.file, image.file.name)
        })
      }

      data.append('form_type', this.$options.name)

      if (this.category && this.category.name) {
        data.append('category', this.category.name)
      }

      data.append('user_id', this.user.id)

      if (this.form && this.form.other_environment) {
        this.form.environment = this.form.other_environment
        delete this.form.other_environment
      }

      if (this.form && this.form.other_odour) {
        this.form.odour = this.form.other_odour
        delete this.form.other_odour
      }

      if (this.form && this.form.other_colour) {
        this.form.colour = this.form.other_colour
        delete this.form.other_colour
      }

      for (const [key, value] of Object.entries(this.form)) {
        data.append(key, value)
      }

      if (!navigator.onLine) {
        let formData = null
        if (this.datetime && this.datetime.date && this.datetime.time) {
          formData = {
            ...this.form,
            'submitted_at': this.formattedDates()
          }
        }
        const errKeys = Object.keys(this.localError)
        const formKeys = Object.keys(this.datetime && formData ? formData : this.form)
        const diff = errKeys.filter(x => !formKeys.includes(x));
        const errors = {}

        diff.map((index) => {
          errors[index] = this.localError[index]
        })

        this.errors = errors
        this.$refs.FormControl.setErrors(errors)

        if (Object.keys(errors).length) {
          const errorAlert = await alertController.create({
            header: this.$t('Failed to submit'),
            subHeader: this.$t('The data was invalid'),
            message: this.$t('Please fill all the required fields'),
            buttons: [this.$t('OK')]
          });

          await errorAlert.present()

          return
        }
      }

      try {
        await ApiService.post('/submission', data)
            .then(() => {
              this.openModal()
              this.$store.dispatch('auth/getUser')
            })
            .catch(async (err) => {
              const errorAlert = await alertController.create({
                header: this.$t('Failed'),
                subHeader: this.$t('Submission Failed'),
                message: err.response.data.message,
                buttons: [this.$t('OK')]
              });

              this.$refs.FormControl.setErrors(err.response.data.errors)
              this.errors = err.response.data.errors

              await errorAlert.present()
            })
      } catch (err) {
        const errorAlert = await alertController.create({
          header: this.$t('Failed to submit'),
          subHeader: this.$t('No Internet Connection'),
          message: this.$t('Your report will be stored and sent once you will be online'),
          buttons: [this.$t('OK')]
        });

        await errorAlert.present()
      }
    },

    formattedDates() {
      if (this.datetime && this.datetime.date && this.datetime.time) {
        const time = new Date(this.datetime.time);
        return moment(this.datetime.date)
            .set('hour', time.getHours())
            .set('minutes', time.getMinutes())
            .set('seconds', time.getSeconds())
            .toISOString()
      }
    },

    formatAMPM(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ':' + minutes + ' ' + ampm;
    },

    setLocationToForm(data) {
      if (data.longitude) {
        this.form.longitude = data.longitude
      }

      if (data.latitude) {
        this.form.latitude = data.latitude
      }
    },

    setImages(data) {
      this.files = [...data]
    },

    toggleDatetime(value) {
      this.differentDate = value
    },

    async openModal() {
      const modal = await modalController
          .create({
            component: CongratulationModal,
            componentProps: {
              resetForm: () => this.form = {},
            },
            cssClass: 'success',
            backdropDismiss:false
          });
      return modal.present();
    },

    hideError(key) {
      if (key === 'created_at') {
        if (this.dates[0] && this.dates[0].date && this.dates[0].time) {
          delete this.errors[key]
          this.$refs.FormControl.setErrors(this.errors)
        }
        return
      } else {
        delete this.errors[key]
        this.$refs.FormControl.setErrors(this.errors)
      }
    },
    async getDeviceInfo() {
      return await Device.getInfo();
    }
  }
}
</script>

<style scoped>

</style>
