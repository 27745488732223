<template>
  <ion-page class="ion-page home-page" id="main-content">
    <ion-content>
      <ion-img class="logo" :src="require('../assets/images/CoastWatchers_logo.png')"></ion-img>
      <ion-item lines="none" class="title">
        <ion-title>
          {{ $t('Welcome Coast Watcher!') }}
        </ion-title>
      </ion-item>
      <ion-item v-if="user && user.name" lines="none" class="subtitle">
        <ion-title size="small">
          {{ user.name }}
        </ion-title>
      </ion-item>

      <swiper
          class="swiper-wrapper"
          slidesPerView="auto"
          :spaceBetween="15"
          :centeredSlides="true"
          :pagination="{clickable: true}"
          :grabCursor="true"
          :allowTouchMove="true"
      >

        <swiper-slide class="slide slide-background-image"
                      :style="`background-image: url(${require('../assets/images/slide-background.png')})`">
          <ion-card>
            <ion-card-header>
              <ion-card-title>{{ $t('Report') }}</ion-card-title>
              <RightArrow @click="$router.push({name: 'Topic'})"></RightArrow>
            </ion-card-header>

            <ion-card-content>
              Coast Watchers is a citizen science program that collects data on Lake Huron from May till October. With
              your help we are able to collect important information on atmospheric conditions, plastic pollution,
              species at risk, algae blooms, storm damage, and human activities along the coast. Thank you for being the
              eyes and ears on Lake Huron!
            </ion-card-content>

            <ion-item lines="none" class="icon-wrapper">
              <WavesShape class="card-waves"></WavesShape>
            </ion-item>
          </ion-card>
        </swiper-slide>

        <swiper-slide class="slide slide-background-image"
                      :style="`background-image: url(${require('../assets/images/slide-background.png')})`">
          <ion-card>
            <ion-card-header>
              <ion-card-title>{{ $t('Training Guide') }}</ion-card-title>
              <RightArrow @click="redirectTo('https://www.lakehuron.ca/cwtraining/')"></RightArrow>
            </ion-card-header>

            <ion-card-content>
              New to Coast Watchers? Visit the Coast Watchers training guide to learn how to monitor the shoreline
              through our general protocol and training videos.
            </ion-card-content>

            <ion-item lines="none" class="icon-wrapper">
              <WavesShape class="card-waves"></WavesShape>
            </ion-item>
          </ion-card>
        </swiper-slide>

        <swiper-slide class="slide slide-background-image"
                      :style="`background-image: url(${require('../assets/images/slide-background.png')})`">
          <ion-card>
            <ion-card-header>
              <ion-card-title>{{ $t(' Learn More') }}</ion-card-title>
              <RightArrow @click="redirectTo('https://www.lakehuron.ca/')"></RightArrow>
            </ion-card-header>

            <ion-card-content>
              Lake Huron Coastal Centre is a registered charity founded in 1998 with the goals of protecting and
              restoring Lake Huron’s coastal environment through education, restoration, and research projects. Click
              here to learn more about the Coastal Centre!
            </ion-card-content>

            <ion-item lines="none" class="icon-wrapper">
              <WavesShape class="card-waves"></WavesShape>
            </ion-item>
          </ion-card>
        </swiper-slide>

        <swiper-slide class="slide slide-background-image"
                      :style="`background-image: url(${require('../assets/images/slide-background.png')})`">
          <ion-card>
            <ion-card-header>
              <ion-card-title>{{ $t('Donate Now!') }}</ion-card-title>
              <RightArrow @click="redirectTo('https://www.lakehuron.ca/donate')"></RightArrow>
            </ion-card-header>

            <ion-card-content>
              As a registered charity, 97% of our work is funded through donations and grants. Your donation today will
              ensure that Lake Huron is protected now and in the future. Click here to donate now!
            </ion-card-content>

            <ion-item lines="none" class="icon-wrapper">
              <WavesShape class="card-waves"></WavesShape>
            </ion-item>
          </ion-card>
        </swiper-slide>

        <swiper-slide class="slide slide-background-image"
                      :style="`background-image: url(${require('../assets/images/slide-background.png')})`">
          <ion-card>
            <ion-card-header>
              <ion-card-title>{{ $t('FAQ') }}</ion-card-title>
              <RightArrow
                  @click="redirectTo('https://www.lakehuron.ca/coast-watchers-frequently-asked-questions')"></RightArrow>
            </ion-card-header>

            <ion-card-content>
              Do you have a question? We have an answer! Visit our
              frequently asked questions page.
            </ion-card-content>

            <ion-item lines="none" class="icon-wrapper">
              <WavesShape class="card-waves"></WavesShape>
            </ion-item>
          </ion-card>
        </swiper-slide>
      </swiper>

      <ion-item lines="none" class="title">
        <ion-title class="title-supported">
          {{ $t('Generously Supported By') }}
        </ion-title>
      </ion-item>

      <ion-list lines="none" class="funders-wrapper">
        <ion-img :src="require('../assets/images/RotaryClubofGoderichLogo.png')"></ion-img>
        <ion-img :src="require('../assets/images/RBCFND_LogoDes_H_rgbPE.png')"></ion-img>
        <ion-img :src="require('../assets/images/BrucePowerTag.png')"></ion-img>
      </ion-list>

      <!--      <swiper-->
      <!--          class="swiper-wrapper funder"-->
      <!--          slidesPerView="3"-->
      <!--          :spaceBetween="15"-->
      <!--          :pagination="{clickable: true}"-->
      <!--          :grabCursor="true"-->
      <!--          :allowTouchMove="true"-->
      <!--      >-->

      <!--        <swiper-slide class="slide slide-background-image">-->
      <!--          <ion-img :src="require('../assets/images/BrucePowerTag.png')"></ion-img>-->
      <!--        </swiper-slide>-->

      <!--        <swiper-slide class="slide slide-background-image">-->
      <!--          <ion-img :src="require('../assets/images/RotaryClubofGoderichLogo.png')"></ion-img>-->
      <!--        </swiper-slide>-->

      <!--        <swiper-slide class="slide slide-background-image">-->
      <!--          <ion-img :src="require('../assets/images/RBCFND_LogoDes_H_rgbPE.png')"></ion-img>-->
      <!--        </swiper-slide>-->
      <!--      </swiper>-->

    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, IonContent, IonicSwiper, IonCard, IonCardHeader, IonCardTitle, IonCardContent} from '@ionic/vue'
import {Swiper, SwiperSlide} from 'swiper/vue'
import SwiperCore, {Navigation, Pagination} from 'swiper'
import 'swiper/swiper-bundle.min.css'
import '@ionic/vue/css/ionic-swiper.css'
import "../theme/pages/home-page.scss"
import RightArrow from "@/components/icons/RightArrow"
import WavesShape from "@/components/icons/WavesShape"
import {mapGetters} from "vuex";

SwiperCore.use([IonicSwiper, Navigation, Pagination]);

export default {
  name: 'Home',
  components: {
    RightArrow,
    WavesShape,
    IonContent,
    IonPage,
    Swiper,
    SwiperSlide,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters("auth", ["user"])
  },

  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },

    onSlideChange() {
      console.log('slide change')
    },
    redirectTo(url) {
      window.open(url, '_blank');
    }
  }
}
</script>
