/* eslint-disable no-console */

import { register } from 'register-service-worker'
let updateFound = false, updated = false;
const handleUpdate = () => {
  if (updateFound && updated) { window.location.reload(); }
}

//ForceUpdate on Staging
if(!localStorage.getItem('cached')) {
  updateFound = true;
  updated = true
  handleUpdate();
  updateFound = false;
  updated = false;
  localStorage.setItem('cached', 'true');
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      updateFound = true;
      handleUpdate();
      console.log('New content is downloading.')
    },
    updated () {
      updated = true;
      handleUpdate();
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
