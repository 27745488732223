<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>plus</title>
    <path d="M32 12.948v6.104c0 0.459-0.372 0.832-0.832 0.832h-11.283v11.283c0 0.46-0.373 0.832-0.832 0.832h-6.104c-0.459 0-0.832-0.372-0.832-0.832v-11.283h-11.283c-0.46 0-0.832-0.373-0.832-0.832v-6.104c0-0.46 0.372-0.832 0.832-0.832h11.283v-11.283c0-0.46 0.373-0.832 0.832-0.832h6.104c0.459 0 0.832 0.372 0.832 0.832v11.283h11.283c0.46 0 0.832 0.373 0.832 0.832z"></path>
  </svg>
</template>

<script>
export default {
  name: "Plus"
}
</script>

<style scoped>

</style>
