<template>
  <DefaultLayout>
    <template v-slot:header>
      <CoastHeader :hasBackButton="false" :title="$t('My submissions')"></CoastHeader>
    </template>
    <SubmissionItem v-for="item in items" :key="item.id" :item="item" @details="routeToDetail($event)"></SubmissionItem>
  </DefaultLayout>
</template>

<script>
import CoastHeader from "@/components/CoastHeader.vue"
import SubmissionItem from "@/components/SubmissionItem.vue"
import DefaultLayout from "@/views/layouts/default.vue"
import ApiService from "@/services/api.service";
import {mapGetters} from "vuex";

export default {
  name: "Submissions",
  components: { CoastHeader, SubmissionItem, DefaultLayout},
  data() {
    return {
      items: null
    }
  },
  computed: {
    ...mapGetters("auth", ["user"])
  },
  created () {
    ApiService.get(`client/user/${this.user.id}`)
      .then(data => {
        this.items = data.data.submission_entries
      })
  },
  methods: {
    routeToDetail(id) {
      this.$router.replace({name: 'Submission-Detail', params: {id: id}})
    }
  }
}
</script>

