import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import Tabs from '../views/Tabs.vue'
import SignIn from '@/views/pages/auth/SignIn.vue'
import SignUp from '@/views/pages/auth/SignUp.vue'
import Recovery from '@/views/pages/auth/Recovery.vue'
import Reset from '@/views/pages/auth/Reset.vue'
import Submissions from '@/views/pages/Submissions.vue'
import Topic from '@/views/pages/Topic.vue'
import Account from '@/views/pages/Account.vue'
import Home from "@/views/Home.vue"
import AtmosphericCondition from '@/views/pages/submissions/AtmosphericCondition.vue'
import AlgaeWashup from '@/views/pages/submissions/AlgaeWashup.vue'
import HumanActivity from '@/views/pages/submissions/HumanActivity.vue'
import PlasticWatch from '@/views/pages/submissions/PlasticWatch.vue'
import StormDamage from '@/views/pages/submissions/StormDamage.vue'
import WildlifeReport from '@/views/pages/submissions/WildlifeReport.vue'
import Splash from '@/views/Splash.vue'


import { loadLocaleMessagesIfNotLoaded } from '@/i18n'
import {TokenService} from "@/services/token.service"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/splash'
  },
  {
    path: '/login',
    name: 'Login',
    component: SignIn,
    meta: {
      public: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: SignUp,
    meta: {
      public: true
    }
  },
  {
    path: '/recovery',
    name: 'Recovery',
    component: Recovery,
    meta: {
      public: true
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
    meta: {
      public: true
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      public: false
    }
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/topic'
      },
      {
        path: 'submissions',
        name: 'Submissions',
        component: Submissions,
        meta: {
          public: false
        }
      },
      {
        path: 'topic',
        name: 'Topic',
        component: Topic,
        meta: {
          public: false
        }
      },
      {
        path: 'account',
        name: 'Account',
        component: Account,
        meta: {
          public: false
        }
      }
    ]
  },
  {
    path: '/algae-wash-up',
    name: 'AlgaeWashup',
    component: AlgaeWashup,
    meta: {
      public: false
    }
  },
  {
    path: '/atmospheric-conditions',
    name: 'AtmosphericCondition',
    component: AtmosphericCondition,
    meta: {
      public: false
    }
  },
  {
    path: '/human-activities',
    name: 'HumanActivity',
    component: HumanActivity,
    meta: {
      public: false
    }
  },
  {
    path: '/plastic-watch',
    name: 'PlasticWatch',
    component: PlasticWatch,
    meta: {
      public: false
    }
  },
  {
    path: '/storm-damage',
    name: 'StormDamage',
    component: StormDamage,
    meta: {
      public: false
    }
  },
  {
    path: '/wildlife-reports',
    name: 'WildlifeReport',
    component: WildlifeReport,
    meta: {
      public: false
    }
  },
  {
    path: '/splash',
    name: 'Splash',
    component: Splash,
    meta: {
      public: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  await loadLocaleMessagesIfNotLoaded()

  const isPublic = to.matched.some(record => record.meta.public)

  const onlyWhenLoggedOut = to.matched.some(
      record => record.meta.onlyWhenLoggedOut
  )

  const loggedIn = !!TokenService.getToken()

  if (!isPublic && !loggedIn) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath }
    })
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next("/tabs")
  }

  next()
})

export default router
