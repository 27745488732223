const TOKEN_KEY = "access_token";

let token = localStorage.getItem(TOKEN_KEY);

const TokenService = {
    getToken() {
        return token;
    },

    saveToken(accessToken: string) {
        localStorage.setItem(TOKEN_KEY, accessToken);
        token = accessToken;
    },

    removeToken() {
        localStorage.removeItem(TOKEN_KEY);
        token = null;
    }
}

export { TokenService };
