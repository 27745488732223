<template>
  <ion-header class="coast-header">
    <ion-toolbar class="coast-toolbar">
      <div class="coast-toolbar-flex">
        <Back class="back-button" @click="closeModal"></Back>
        <ion-title>
          {{ title }}
        </ion-title>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" v-if="selected && selected.form">
    <ion-item lines="none" v-if="selected && selected.form && selected.form.files.length">
      <ion-img v-for="file in selected.form.files" :src="file.url_view" :key="file.id" class="detail-image"></ion-img>
    </ion-item>

    <ion-item lines="none" v-for="(value, key) in selected.form" :key="key" :class="'submissions-details' + (!hideKeys(key) ? '' : ' none')">
      <div v-if="!hideKeys(key)" :class="key">
        {{ `${formatKeys( key )} : ${ formatValue(key,value)}`}}
      </div>
    </ion-item>

    <ion-item lines="none" v-if="selected.form && selected.form.notes" :class="'submissions-details'">
      <div :class="key">
        Notes: {{ selected.form.notes }}
      </div>
    </ion-item>

    <ion-item v-if="selected && selected.form && selected.form.algae_seen" lines="none" class="image">
      <ion-img :src="selected.form.algae_seen" :class="key"></ion-img>
    </ion-item>

    <ion-item lines="none" class="status-item">
      <ion-button :class="selected.status">
        {{ selected.status }}
      </ion-button>
    </ion-item>
    <div class="shape-wave-wrapper">
      <WavesShape class="shape-wave"></WavesShape>
    </div>
  </ion-content>
</template>

<script>
  import {IonContent, IonHeader, IonTitle, IonToolbar, modalController} from '@ionic/vue';
  import WavesShape from "@/components/icons/WavesShape"
  import Back from "@/components/icons/Arrow.vue"
  import {defineComponent} from 'vue';
  import ApiService from "@/services/api.service";

  export default defineComponent({
    name: 'Modal',
    props: {
      title: {type: String, default: 'Super Modal'},
      id: {type: Number, default: null},
    },
    data() {
      return {
        keysArray: [
          'id',
          'created_by_id',
          'updated_by_id',
          'deleted_by_id',
          // 'created_at',
          'updated_at',
          'deleted_at',
          'files',
          'longitude',
          'latitude',
          'notes'
        ],
        selected: null
      }
    },
    components: {IonContent, IonHeader, IonTitle, IonToolbar, Back, WavesShape},
    beforeCreate() {
      ApiService.get(`client/submission-entries/${this.id}`)
        .then(data => {
          this.selected = data.data
        })
    },
    methods: {
      async closeModal() {
        modalController.dismiss()
      },
      formatKeys (string) {
        const newString = string.replace(/_/g, ' ')
        return newString.charAt(0).toUpperCase() + newString.slice(1)
      },
      formatValue (key, value) {
        if(typeof value === 'number') {
          if (value === 1) {
            return 'YES'
          }
          if (value === 0) {
            return 'NO'
          }
        }

        if (key === 'submitted_at') {
          return this.formatDate(value)
        }

        if (key === 'created_at') {
          return this.formatDate(value)
        }

        if (!value) {
          this.keysArray.push(key)
        }
        return value
      },
      formatDate (date) {
        return new Date(date).toLocaleDateString('en-US', {hour: 'numeric', hour12: true, minute: 'numeric'}).replace(/\//g, '-')
      },
      hideKeys (key) {
        return this.keysArray.includes(key)
      }
    }
  });
</script>
