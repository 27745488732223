import axios, { AxiosRequestConfig } from "axios";
import {TokenService} from "@/services/token.service";
import {loadingController} from '@ionic/vue';
import router from "@/router";
import store from "@/store";

const ApiService = {
    _requestInterceptor: 0,
    _responseInterceptor: 0,
    _loader: null as Promise<HTMLIonLoadingElement> | null,
    _loadingIndex: 0,

    init(baseURL: string | undefined) {
        axios.defaults.baseURL = baseURL;
        this.mountRequestInterceptor();
        this.mountResponseInterceptor();
        TokenService.getToken() && store.dispatch('auth/getUser')
          .then(() => {
              router.replace({ name: 'Home' })
          })
          .catch(() => {
              TokenService.removeToken();
              router.replace({ name: 'Login' })
          });
    },

    get(resource: string) {
        return axios.get(resource);
    },

    post(resource: string, data: any) {
        return axios.post(resource, data);
    },

    put(resource: string, data: any) {
        return axios.put(resource, data);
    },

    delete(resource: string) {
        return axios.delete(resource);
    },

    customRequest(data: AxiosRequestConfig) {
        return axios(data);
    },

    showLoader() {
        this._loadingIndex++;
        if (!this._loader && this._loadingIndex > 0) {
            this._loader = (async () => {
                const loading = await loadingController.create({
                    message: 'Please wait...'
                });
                await loading.present();

                return loading;
            })()
        }
    },

    async hideLoader() {
        this._loadingIndex--;
        if (this._loader && this._loadingIndex <= 0) {
            this._loadingIndex = 0;
            await this._loader;
            this._loader = null;
            return await loadingController.dismiss();
        }
        return false;
    },

    mountRequestInterceptor() {
        this._requestInterceptor = axios.interceptors.request.use(async config => {
            const token = TokenService.getToken();
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }

            this.showLoader();

            return config;
        });
    },

    mountResponseInterceptor() {
        this._responseInterceptor = axios.interceptors.response.use(
            response => {
                this.hideLoader();
                return response;
            },
            async error => {
                this.hideLoader();
                if (error.request.status === 401) {
                    TokenService.removeToken();
                    router.replace({ name: 'Login' })
                }
                throw error;
            }
        );
    }
}

export default ApiService;
