import ApiService from "./api.service";
import { TokenService } from "./token.service";
import { AxiosRequestConfig } from "axios";
import qs from "qs";

class AuthenticationError extends Error {
    errorCode: any;
    errors: object;
    constructor(errorCode: any, message: string | undefined, errors: object) {
        super(message);
        this.name = this.constructor.name;
        if (message != null) {
            this.message = message;
        }
        this.errorCode = errorCode;
        this.errors = errors;
    }
}

const AuthService = {
    signIn: async function(signInData: any) {
        const requestData: AxiosRequestConfig = {
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            url: "/auth/login",
            data: qs.stringify({
                email: signInData.email,
                password: signInData.password,
                appId: process.env.VUE_APP_CLIENT_ID
            })
        };

        try {
            const response = await ApiService.customRequest(requestData);
            TokenService.saveToken(response.data.data.access_token);
            return response.data.access_token;
        } catch (error) {
            this.catchError(error);
        }
    },

    getUser: async function() {
        const requestData: AxiosRequestConfig = {
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            url: "/auth/user"
        };

        try {
            const response = await ApiService.customRequest(requestData);
            return response.data;
        } catch (error) {
            this.catchError(error);
        }
    },

    refreshToken: async function() {
        const requestData: AxiosRequestConfig = {
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            url: "/auth/refresh"
        };

        try {
            const response = await ApiService.customRequest(requestData);

            TokenService.saveToken(response.data.data.access_token);
            return response.data.data.access_token;
        } catch (error: any) {
            throw new AuthenticationError(
                error.response.status,
                error.response.data.message,
                error.response.data.errors,
            );
        }
    },
    signOut: async function() {
        const requestData: AxiosRequestConfig = {
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            url: "/auth/logout",
            data: qs.stringify({
                appId: process.env.VUE_APP_CLIENT_ID
            })
        };

        try {
            await ApiService.customRequest(requestData);
            TokenService.removeToken();
        } catch (error) {
            this.catchError(error);
        }
    },

    signUp: async function(data: any) {
        const signupData: AxiosRequestConfig = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            url: "/auth/register",
            data: data
        };

        try {
            await ApiService.customRequest(signupData);
        } catch (error) {
            this.catchError(error);
        }
    },
    catchError: function(error: any) {
        let status;
        let description;
        let errors;

        if (error.response === undefined) {
            status = error.message;
            description = error.message;
        } else {
            status = error.response.status;
            description = error.response.data.message;
            errors = error.response.data.errors;
        }

        throw new AuthenticationError(status, description, errors || {});
    }
}

export { AuthenticationError, AuthService };
