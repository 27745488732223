<template>
  <DefaultLayout>
    <template v-slot:header>
      <CoastHeader :hasBackButton="true" :title="$t('Plastic Watch')"></CoastHeader>
    </template>
    <FormControl @onSubmit="handleSubmit" autocomplete="off" ref="FormControl">
      <FieldWrapper ionItemClass="camera-item" name="files">
        <UploadImage @input="setImages"></UploadImage>
      </FieldWrapper>

      <FieldWrapper>
        <ion-text lines="none" color="primary">
          Current Date: {{ currentDatetime.date }}, {{ formatAMPM(currentDatetime.time) }}
        </ion-text>
      </FieldWrapper>

      <ion-item class="submission-text" lines="none">
        <ion-row>
          <ion-text>{{ $t('Was the observation date different?') }}</ion-text>
          <ion-text @click="toggleDatetime(true)" class="add" color="primary" v-if="!differentDate">
            {{ $t('+ Add another date') }}
          </ion-text>
          <ion-text @click="toggleDatetime(false)" class="add" color="primary" v-else>
            {{ $t('- Remove the observation date') }}
          </ion-text>
        </ion-row>
      </ion-item>

      <FieldWrapper v-if="differentDate" :label="$t('Date *')" ionItemClass="border-full" name="submitted_at">
        <ion-datetime :placeholder="$t('Select Date')" @ionChange="hideError('submitted_at')"
                      presentation="month-day-year"
                      v-model="datetime.date"></ion-datetime>
        <Calendar class="date-icon"></Calendar>
      </FieldWrapper>

      <FieldWrapper v-if="differentDate" :label="$t('Time *')" ionItemClass="border-full" name="submitted_at">
        <ion-datetime :placeholder="$t('Select Time')" @ionChange="hideError('submitted_at')" display-format="HH:mm"
                      v-model="datetime.time"></ion-datetime>
        <Time class="time-icon"></Time>
      </FieldWrapper>

      <FieldWrapper :label="$t('Plastic Washup *')" ionItemClass="border-full" name="plastic_washup">
        <ion-radio-group @ionChange="hideError('plastic_washup')" class="radio-group" v-model="form.plastic_washup">
          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="1" slot="start"></ion-radio>
            <ion-label>{{ $t('Yes') }}</ion-label>
          </ion-item>

          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="0" slot="start"></ion-radio>
            <ion-label>{{ $t('No') }}</ion-label>
          </ion-item>
        </ion-radio-group>
      </FieldWrapper>

      <FieldWrapper :label="$t('Beach Cleanup needed *')" ionItemClass="border-full" name="beach_cleanup_needed">
        <ion-radio-group @ionChange="hideError('beach_cleanup_needed')" class="radio-group"
                         v-model="form.beach_cleanup_needed">
          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="1" slot="start"></ion-radio>
            <ion-label>{{ $t('Yes') }}</ion-label>
          </ion-item>

          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="0" slot="start"></ion-radio>
            <ion-label>{{ $t('No') }}</ion-label>
          </ion-item>
        </ion-radio-group>
      </FieldWrapper>

      <FieldWrapper :label="$t('Beach Cleanup done *')" ionItemClass="border-full" name="beach_cleanup_done">
        <ion-radio-group @ionChange="hideError('beach_cleanup_done')" class="radio-group"
                         v-model="form.beach_cleanup_done">
          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="1" slot="start"></ion-radio>
            <ion-label>{{ $t('Yes') }}</ion-label>
          </ion-item>

          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="0" slot="start"></ion-radio>
            <ion-label>{{ $t('No') }}</ion-label>
          </ion-item>
        </ion-radio-group>
      </FieldWrapper>

      <FieldWrapper :label="$t('Litter removed (lbs) *')" ionItemClass="border-full" name="litter_removed">
        <ion-input :placeholder="$t('Litter removed')" @ionChange="hideError('litter_removed')" inputmode="decimal" pattern="^(?:\d+(?:[.,]\d{1,2})?|\d+)$"
                   v-model="form.litter_removed"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Most common type of litter *')" ionItemClass="border-full" name="common_types">
        <ion-select multiple="true" :placeholder="$t('Select')" @ionChange="hideError('common_types')" interface="action-sheet"
                    v-model="form.common_types">
          <ion-select-option :key="i" :value="i" v-for="i in litters">{{ i }}</ion-select-option>
        </ion-select>
        <ChevronDown class="chevron-down-icon"></ChevronDown>
      </FieldWrapper>

      <FieldWrapper v-if="form.common_types && form.common_types.includes('Other')" :label="$t('Other')" ionItemClass="border-full"
                    name="common_types_other">
        <ion-input :placeholder="$t('Other')" @ionChange="hideError('common_types_other')"
                   v-model="form.common_types_other"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Micro plastic *')" ionItemClass="border-full" name="micro_plastic">
        <ion-radio-group @ionChange="hideError('micro_plastic')" class="radio-group" v-model="form.micro_plastic">
          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="1" slot="start"></ion-radio>
            <ion-label>{{ $t('Yes') }}</ion-label>
          </ion-item>

          <ion-item class="coast-radio" lines="none">
            <ion-radio :value="0" slot="start"></ion-radio>
            <ion-label>{{ $t('No') }}</ion-label>
          </ion-item>
        </ion-radio-group>
      </FieldWrapper>

      <FieldWrapper v-if="form.micro_plastic === 1" :label="$t('Micro plastic *')" ionItemClass="border-full"
                    name="micro_plastic_option">
        <ion-select aria-multiselectable="true" :placeholder="$t('Select')"
                    @ionChange="hideError('micro_plastic_option')" interface="action-sheet"
                    v-model="form.micro_plastic_option" multiple="true">
          <ion-select-option :key="i" :value="i" v-for="i in microPlasticOption">{{ i }}</ion-select-option>
        </ion-select>
        <ChevronDown class="chevron-down-icon"></ChevronDown>
      </FieldWrapper>

      <FieldWrapper v-if="form.micro_plastic_option && form.micro_plastic_option.includes('Other')" :label="$t('Other')" ionItemClass="border-full"
                    name="micro_plastic_option_other">
        <ion-input :placeholder="$t('Other')" @ionChange="hideError('micro_plastic_option_other')"
                   v-model="form.micro_plastic_option_other"></ion-input>
      </FieldWrapper>

      <FieldWrapper :label="$t('Notes')" ionItemClass="border-full">
        <ion-textarea :placeholder="$t('Enter any notes here...')" cols="20" rows="7"
                      v-model="form.notes"></ion-textarea>
      </FieldWrapper>

      <GoogleUserLocation
          :location="{
              longitude: form.longitude,
              latitude: form.latitude
            }"
          @userLocation="setLocationToForm">
      </GoogleUserLocation>

      <ion-item class="primary-button" lines="none">
        <ion-button expand="block" type="submit">{{ $t('Submit') }}</ion-button>
      </ion-item>
    </FormControl>
  </DefaultLayout>
</template>

<script>
import {
  IonDatetime,
  IonItem,
  IonText,
  IonSelect,
  IonSelectOption,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonTextarea,
  IonInput,
  IonRow
} from '@ionic/vue'
import DefaultLayout from "@/views/layouts/default.vue"
import CoastHeader from "@/components/CoastHeader.vue"
import GoogleUserLocation from "@/components/GoogleUserLocation.vue"
import Calendar from "@/components/icons/Calendar.vue"
import Time from "@/components/icons/Time.vue"
import ChevronDown from "@/components/icons/ChevronDown.vue"
import FormControl from "@/components/FormControl"
import FieldWrapper from "@/components/FieldWrapper"
import UploadImage from "@/components/UploadImage"
import '@/theme/components/form.scss'
import moment from "moment"
import ApiService from "@/services/api.service";
import {mapGetters} from "vuex";
import {alertController, modalController} from "@ionic/core";
import CongratulationModal from "@/components/CongratulationModal";
import { Device } from '@capacitor/device';

export default {
  name: "PlasticWatch",
  components: {
    IonDatetime,
    IonItem,
    IonText,
    IonSelect,
    IonSelectOption,
    IonRadioGroup,
    IonRadio,
    IonLabel,
    IonTextarea,
    IonInput,
    IonRow,
    FormControl,
    FieldWrapper,
    UploadImage,
    DefaultLayout,
    CoastHeader,
    Calendar,
    Time,
    ChevronDown,
    GoogleUserLocation
  },

  data() {
    return {
      litters: [
        'Cigarette butts',
        'Plastic bottles',
        'Beverage cans',
        'Bottle caps',
        'Food wrappers',
        'Straws',
        'Coffee cups',
        'Fishing line',
        'Other'
      ],
      microPlasticOption: [
        'Nurdles',
        'Microbeads',
        'Foam',
        'Fragments',
        'Other'
      ],
      form: {},
      currentDatetime: {
        date: `${moment(new Date()).format('MMMM')} ${moment(new Date()).format('D-Y')}`,
        time: new Date()
      },
      datetime: {
        date: null,
        time: null
      },
      differentDate: false,
      files: [],
      errors: [],
      localError: {
        "created_at": ["The created at field is required."],
        "plastic_washup": ["The plastic washup field is required."],
        "beach_cleanup_needed": ["The beach cleanup needed field is required."],
        "beach_cleanup_done": ["The beach cleanup done field is required."],
        "litter_removed": ["The litter removed field is required."],
        "common_types": ["The common types field is required."],
        "micro_plastic": ["The Micro plastic field is required."]
      }
    }
  },

  computed: {
    ...mapGetters("auth", ["user"])
  },


  methods: {
    async handleSubmit() {
      const data = new FormData()

      const formattedDates = this.formattedDates(this.dates)

      const deviceInfo = await this.getDeviceInfo();
      data.append('device', JSON.stringify(deviceInfo));

      if (this.datetime && this.datetime.time && this.datetime.date) {
        data.append('submitted_at', this.formattedDates(this.datetime))
      }

      if (this.files) {
        this.files.forEach(image => {
          data.append('files[]', image.file, image.file.name)
        })
      }

      data.append('form_type', this.$options.name)

      data.append('user_id', this.user.id)

      if (this.form && this.form.common_types_other) {
        for (const index in this.form.common_types) {
          if (this.form.common_types[index] === 'Other') {
            this.form.common_types[index] = this.form.common_types_other
          }
        }
        delete this.form.common_types_other
      }

      if (this.form && this.form.micro_plastic_option_other) {
        for (const index in this.form.micro_plastic_option) {
          if (this.form.micro_plastic_option[index] === 'Other') {
            this.form.micro_plastic_option[index] = this.form.micro_plastic_option_other
          }
        }
        delete this.form.micro_plastic_option_other
      }

      for (const [key, value] of Object.entries(this.form)) {
        data.append(key, value)
      }

      if (!navigator.onLine) {
        let formData = null
        if (this.datetime && this.datetime.date && this.datetime.time) {
          formData = {
            ...this.form,
            'submitted_at': this.formattedDates()
          }
        }
        const errKeys = Object.keys(this.localError)
        const formKeys = Object.keys(this.datetime && formData ? formData : this.form)
        const diff = errKeys.filter(x => !formKeys.includes(x));
        const errors = {}

        diff.map((index) => {
          errors[index] = this.localError[index]
        })

        this.errors = errors
        this.$refs.FormControl.setErrors(errors)

        if (Object.keys(errors).length) {
          const errorAlert = await alertController.create({
            header: this.$t('Failed to submit'),
            subHeader: this.$t('The data was invalid'),
            message: this.$t('Please fill all the required fields'),
            buttons: [this.$t('OK')]
          });

          await errorAlert.present()

          return
        }
      }

      try {
        await ApiService.post('/submission', data)
            .then(data => {
              this.openModal()
              this.$store.dispatch('auth/getUser')
            })

            .catch(async (err) => {
              const errorAlert = await alertController.create({
                header: this.$t('Failed'),
                subHeader: this.$t('Submission Failed'),
                message: err.response.data.message,
                buttons: [this.$t('OK')]
              });

              this.$refs.FormControl.setErrors(err.response.data.errors)
              this.errors = err.response.data.errors

              await errorAlert.present()
            })
      } catch (err) {
        const errorAlert = await alertController.create({
          header: this.$t('Failed to submit'),
          subHeader: this.$t('No Internet Connection'),
          message: this.$t('Your report will be stored and sent once you will be online'),
          buttons: [this.$t('OK')]
        });

        await errorAlert.present()
      }
    },

    formattedDates() {
      if (this.datetime && this.datetime.date && this.datetime.time) {
        const time = new Date(this.datetime.time);
        return moment(this.datetime.date)
            .set('hour', time.getHours())
            .set('minutes', time.getMinutes())
            .set('seconds', time.getSeconds())
            .toISOString()
      }
    },

    formatAMPM(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ':' + minutes + ' ' + ampm;
    },

    setLocationToForm(data) {
      if (data.longitude) {
        this.form.longitude = data.longitude
      }

      if (data.latitude) {
        this.form.latitude = data.latitude
      }
    },

    setImages(data) {
      this.files = [...data]
    },

    toggleDatetime(value) {
      this.differentDate = value
    },

    async openModal() {
      const modal = await modalController
          .create({
            component: CongratulationModal,
            componentProps: {
              resetForm: () => this.form = {},
            },
            cssClass: 'success',
            backdropDismiss:false
          });
      return modal.present();
    },

    hideError(key) {
      if (key === 'micro_plastic') {
        delete this.form.micro_plastic_option
      }
      if (key === 'created_at') {
        if (this.dates[0] && this.dates[0].date && this.dates[0].time) {
          delete this.errors[key]
          this.$refs.FormControl.setErrors(this.errors)
        }
        return
      } else {
        delete this.errors[key]
        this.$refs.FormControl.setErrors(this.errors)
      }
    },
    async getDeviceInfo() {
      return await Device.getInfo();
    }
  }
}
</script>

<style scoped>

</style>
