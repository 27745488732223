import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

let locale = 'en'
const SUPPORT_LOCALES = ['en', 'nl']
const i18n = createI18n({locale: 'en'})

function setLocale(loc: string) {
    locale = loc
    // TODO: trigger a router reload if something changed
}

async function loadLocaleMessages(i18n: any, locale: string) {

    // load locale messages with dynamic import
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `./assets/i18n/${locale}.json`
      )

    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages.default)

    i18n.global.locale = locale

    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */

    const html = document.querySelector('html')
    if (html) {
        html.setAttribute('lang', locale)
    }

    return nextTick()
}

async function loadLocaleMessagesIfNotLoaded() {
    // use locale if paramsLocale is not in SUPPORT_LOCALES
    if (!SUPPORT_LOCALES.includes(locale)) {
        return Promise.resolve()
    }

    // load locale messages
    if (!i18n.global.availableLocales.includes(locale)) {
        await loadLocaleMessages(i18n, locale)
    }

    return Promise.resolve()
}

export {
    i18n,
    SUPPORT_LOCALES,
    setLocale,
    loadLocaleMessagesIfNotLoaded
};
