import {AxiosRequestConfig} from "axios";
import qs from "qs";
import ApiService from "@/services/api.service";
import {TokenService} from "@/services/token.service";


const SubmissionsService = {
    setSubmissions: async function(data: any) {
        console.log(Object.fromEntries(data))

        // const requestData: AxiosRequestConfig = {
        //     method: "post",
        //     headers: {
        //         "Content-Type": "application/x-www-form-urlencoded"
        //     },
        //     url: "/user/submissions",
        //     data: qs.stringify(Object.fromEntries(data))
        // };
        //
        // try {
        //     const response = await ApiService.customRequest(requestData)
        //
        //     console.log(response.data)
        // } catch (error) {
        //     console.log('submissionsError', error)
        // }
    },

    getSubmissions: async function() {
        const requestData: AxiosRequestConfig = {
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            url: `/client/submissions`
        };

        try {
            const response = await ApiService.customRequest(requestData);
            console.log('submissionsResponse', response)
            return response.data;
        } catch (error) {
            console.log('submissionsError', error)
        }
    },
}


export { SubmissionsService };
