<template>
  <ion-page class="ion-page splash-container" id="splash">
    <ion-content class="background">
      <ion-img :src="require('../assets/images/CoastWatchers_logo.png')"></ion-img>

<!--      <div class="donate">-->
<!--        <div>{{ 'Would you like to give your support?' }}</div>-->
<!--        <a href="https://www.lakehuron.ca/donate-canadahelpsform">{{ 'Donate to the Coast Centre' }}</a>-->
<!--      </div>-->
<!--      <div class="website-link">-->
<!--        <div>{{ 'Visit our website to learn more about the Coast Centre' }}</div>-->
<!--        <a href="https://www.lakehuron.ca">{{ 'www.lakehuron.ca' }}</a>-->
<!--      </div>-->
      </ion-content>
  </ion-page>
</template>

<script>
  import {IonPage} from "@ionic/vue";
  import "../theme/pages/splash.scss"
  import {mapGetters} from "vuex";

  export default {
    name: "Splash",
    components: { IonPage },

    computed: {
      ...mapGetters("auth", ["user"]),
    },

    mounted() {
      setTimeout(() => {
        this.$router.replace(this.user ? { name: 'Home' } : { name: 'Login'})
      }, 1500)
    }
  }
</script>
