<template>
      <div class="wrapper">
        <div class="close-wrapper">
          <Close @click="closeModal"></Close>
        </div>

        <div class="shape-wave-wrapper">
          <WavesShape class="shape-wave"></WavesShape>
        </div>
        <div class="success-title">
          {{ $t('Thank you for being the eyes and ears of Lake Huron!')}}
        </div>
        <div class="body-message">
          <div class="line">
            {{ $t('You have successfully sent your submission.')}}
          </div>

          <div class="line">
            {{ $t('Our team will now carefully go through it’s content and take the needed actions.')}}
          </div>

          <div class="line">
            {{ $t('Keep your eyes open and let us know when you notice anything else')}}
          </div>
        </div>
      </div>
</template>

<script>
  import {modalController} from '@ionic/vue';
  import WavesShape from "@/components/icons/WavesShape"
  import Close from "@/components/icons/Close.vue"
  import {defineComponent} from 'vue';

  export default defineComponent({
    name: 'Modal',
    props: {
      title: {type: String, default: 'Congratulation Modal'},
      id: {type: Number, default: null},
      resetForm: {type: Function, default: null},
    },
    components: { Close, WavesShape},
    methods: {
      async closeModal() {
        this.$router.replace({ name: 'Submissions'})
        this.resetForm()
        modalController.dismiss()
      }
    }
  });
</script>
