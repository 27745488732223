<template>
  <DefaultLayout>
    <FormControl ref="FormControl" @onSubmit="handleRecovery" autocomplete="off">
      <ion-list lines="none" class="border-full">
        <ion-item lines="none" class="auth-title">
          <ion-title>
            {{ $t('Recovery password') }}
          </ion-title>
        </ion-item>

        <FieldWrapper name="email" ionItemClass="auth-input">
          <ion-input
              type="email"
              inputmode="email"
              v-model="form.email"
              id="email"
              :placeholder="$t('E-mail')"
              autocomplete="off"
              required>
          </ion-input>
        </FieldWrapper>

        <ion-item lines="none" class="primary-button">
          <ion-button expand="block" type="submit">{{ $t('Send password reset link') }}</ion-button>
        </ion-item>
      </ion-list>
    </FormControl>
  </DefaultLayout>
</template>

<script>
import {IonTitle, IonItem, IonButton, IonInput, IonList, alertController} from '@ionic/vue'
import DefaultLayout from "@/views/layouts/default.vue"
import FieldWrapper from "@/components/FieldWrapper.vue"
import FormControl from "@/components/FormControl"
import ApiService from "@/services/api.service"


export default {
  name: 'Recovery',
  components: {FormControl, IonTitle, IonItem, IonButton, IonList, IonInput, DefaultLayout, FieldWrapper},

  data() {
    return {
      form: {}
    }
  },

  methods: {
    handleRecovery() {
      ApiService.post('auth/recovery', this.form)
          .then(async () => {
            const successAlert = await alertController.create({
              header: this.$t('Success'),
              message: this.$t('We will send you a password reset link'),
              buttons: [this.$t('OK')]
            });
            this.$refs.FormControl.setErrors({})
            await successAlert.present()
                .then(() => {
                  this.$router.push({name: 'Login'})
                })
          })
          .catch(async (err) => {
            const errorAlert = await alertController.create({
              header: this.$t('Failed'),
              subHeader: this.$t('Recovery Failed'),
              message: err.response.data.message,
              buttons: [this.$t('OK')]
            });
            this.$refs.FormControl.setErrors(err.response.data.errors)

            await errorAlert.present()
          })
    }
  }
}
</script>

<style scoped>

</style>
