
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { ellipse, square, triangle } from 'ionicons/icons';
import Clipboard from "@/components/icons/Clipboard.vue"
import IconPlus from "@/components/icons/Plus.vue"
import Settings from "@/components/icons/Settings.vue"
import WavesShape from "@/components/icons/WavesShape.vue"
import { useRoute  } from 'vue-router';
import { computed } from "vue";

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet, Clipboard, IconPlus, Settings, WavesShape },
  setup() {
    const currentRoute = computed(() => {
      return useRoute().name
    })

    return {
      currentRoute,
      ellipse,
      square,
      triangle,
    }
  },
}
