<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>plus-circular-button</title>
    <path d="M22.043 13.946h-3.989v-3.989c0-1.135-0.92-2.054-2.054-2.054s-2.054 0.92-2.054 2.054v3.989h-3.989c-1.135 0-2.054 0.92-2.054 2.054s0.92 2.054 2.054 2.054h3.989v3.989c0 1.135 0.92 2.054 2.054 2.054s2.054-0.92 2.054-2.054v-3.989h3.989c1.135 0 2.054-0.92 2.054-2.054s-0.92-2.054-2.054-2.054z"></path>
    <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 27.891c-6.572 0-11.891-5.318-11.891-11.891s5.318-11.891 11.891-11.891c6.572 0 11.891 5.318 11.891 11.891s-5.319 11.891-11.891 11.891z"></path>
  </svg>
</template>

<script>
export default {
  name: "CirclePlus"
}
</script>

<style scoped>

</style>
