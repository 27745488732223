<template>
  <ion-item class="submission-item" @click="openModal">
    <ion-label>
      <div class="title">{{ spaceBetweenWords(item.form_type)}}</div>
      <div class="date-wrapper">
        <Calendar></Calendar>
        <div class="date">
          <strong>Date:</strong>
          <p>{{ formatDate(item.created_at) }}</p>
        </div>
      </div>
      <div class="status-wrapper">
        <ChargingCircle></ChargingCircle>
        <div class="status">
          <strong>Status:</strong>
          <div :class="item.status" class="status-item">
            {{ item.status }}
          </div>
        </div>
      </div>
    </ion-label>
    <Arrow class="arrow"></Arrow>
  </ion-item>
</template>

<script>
  import {IonItem, modalController} from '@ionic/vue'
  import ChargingCircle from "@/components/icons/ChargingCircle.vue"
  import Calendar from "@/components/icons/Calendar.vue"
  import Arrow from "@/components/icons/Arrow.vue"
  import "@/theme/components/submission-item.scss"
  import Modal from '@/components/Modal.vue'

  export default {
    name: "SubmissionItem",
    components: {IonItem, ChargingCircle, Calendar, Arrow},

    props: {
      item: {
        type: Object,
        required: true,
        default: () => ({})
      }
    },

    methods: {
      formatDate(date) {
        return new Date(date).toLocaleDateString('en-US', {hour: 'numeric', hour12: true, minute: 'numeric'}).replace(/\//g, '-')
      },
      spaceBetweenWords(string) {
        return string.replace(/([A-Z])/g, ' $1').trim()
      },
      sendIdToParent () {
        this.$emit('details', this.item.id)
      },
      async openModal() {
        const modal = await modalController
          .create({
            component: Modal,
            cssClass: 'my-custom-class',
            componentProps: {
              title: this.spaceBetweenWords(this.item.form_type),
              id: this.item.id
            },
          });
        return modal.present();
      }
    }
  }
</script>

<style scoped>

</style>
