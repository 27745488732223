<template>
  <div class="overlay">
    <GMapMap
        :center="center"
        :zoom="18"
        map-type-id="terrain"
        style="width: 100vw; height: 900px"
        ref="myMapRef"
        v-bind:class="{ drag: isDragging, noLocation: !location.hasValue && !form.longitude && !form.latitude}"
        :options="{
         zoomControl: false,
         mapTypeControl: false,
         scaleControl: false,
         streetViewControl: false,
         rotateControl: false,
         fullscreenControl: false,
         disableDefaultUi: false
     }"
      @center_changed="updateCoordinates"
      @dragstart="location.longitude && location.latitude ? dragStart() : ''"
      @dragend="location.longitude && location.latitude  ? dragEnd() : ''"
    >
    </GMapMap>
    <ion-item v-if="!location.hasValue && !this.form.longitude && !this.form.latitude" lines="none" class="no-location">
      <div class="enable-location" @click="changeGeoPermission">{{ $t('Location not shared. Click to enable it') }}</div>
    </ion-item>
  </div>
</template>

<script>
import {isEqual} from "lodash"
import './../theme/components/vue-map.scss'

export default {
  name: "GoogleUserLocation",

  props: {
    location: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      form: {},
      isDragging: false
    }
  },

  computed: {
    center: function () {
      return {lat: Number(this.form.latitude || 1), lng: Number(this.form.longitude || 1)}
    },
    markers: function () {
      return [
        {
          position: {lat: Number(this.form.latitude), lng: Number(this.form.longitude)}
        }
      ]
    }
  },

  watch: {
    location: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, this.form))
          this.form = {...newVal}
      }
    },

    form: {
      handler(newVal, oldVal) {
        if (newVal && !isEqual(newVal, oldVal) && !isEqual(this.location, newVal)) {
          this.$emit('userLocation', newVal)
        }
      }
    }
  },

  created() {
    this.getUserPosition()
  },

  methods: {
    getUserPosition(){
      window.navigator.geolocation.getCurrentPosition(position => {
        this.form = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }
      })
    },
    changeGeoPermission() {
      if (!this.location.hasValue) {
       return navigator.permissions.query({name: 'geolocation'}).then(function (result) {
          if (result.state === 'denied') {
            if (window.confirm('In order to proceed to submit a report you need enable your location and refresh the page. Clicking "OK" you\'ll be redirected to a new page explaining how to achieve this!')) {
              window.open('https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop&oco=1', '_blank');
            }
          }
        })
      }
      this.getUserPosition()
    },
    updateCoordinates({ lat, lng }) {
      this.tempForm = {
        latitude: Number(lat()),
        longitude: Number(lng()),
      };
    },
    dragStart() {
      this.isDragging = !this.isDragging
    },
    dragEnd() {
      this.isDragging = !this.isDragging
      this.form = {...this.tempForm};
    }
  }
}
</script>

<style scoped>


</style>
