import { createApp  } from 'vue'
import App from './App.vue'
import VueRx from "@nopr3d/vue-next-rx"
import router from './router'
import { i18n } from './i18n'
// @ts-ignore
import  VueGoogleMaps from '@fawmi/vue-google-maps'

/* Auth */
import ApiService from "@/services/api.service"
import {TokenService} from "@/services/token.service"

import { IonicVue } from '@ionic/vue'

import { defineCustomElements } from '@ionic/pwa-elements/loader'

import store from './store'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/main.scss'

import './registerServiceWorker'

const app = createApp(App)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLEMAPS_KEY
    }
  })
  .use(VueRx)
  .use(IonicVue)
  .use(i18n)
  .use(store)
  .use(router)

ApiService.init(process.env.VUE_APP_ROOT_API)

router.isReady().then(() => {
  app.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLEMAPS_KEY,
      libraries: 'places',
    },
  })
  app.mount('#app')
})

defineCustomElements(window)
