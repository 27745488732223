<template>
  <DefaultLayout>
    <div class="submissions-content">
        <CoastHeader :hasBackButton="null" :title="$t('Your submission topic')"></CoastHeader>

      <ion-grid>
        <ion-row>
          <ion-col v-for="submission in submissions" :key="submission" size="6">
            <SubmissionCard :item="submission" @click="routeTo(submission.routeName)"></SubmissionCard>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/views/layouts/default.vue"
import SubmissionCard from "@/components/SubmissionCard.vue"
import Back from "@/components/icons/Arrow.vue"
import { IonGrid, IonRow, IonCol, IonText } from '@ionic/vue'
import { mapGetters } from "vuex";
import "@/theme/components/submissions.scss"
import CoastHeader from "@/components/CoastHeader";

export default {
  name: "Topic",
  components: { IonGrid, IonRow, IonCol, SubmissionCard, DefaultLayout, CoastHeader },

  data() {
    return {
      submissions: [
        { routeName: 'AtmosphericCondition', label: this.$t('Atmospheric Conditions'), isRequired: true, hasErrors: false },
        { routeName: 'PlasticWatch', label: this.$t('Plastic Watch'), isRequired: false, hasErrors: false },
        { routeName: 'WildlifeReport', label: this.$t('Wildlife Reports'), isRequired: false, hasErrors: false },
        { routeName: 'AlgaeWashup', label: this.$t('Algae Wash-up'), isRequired: false, hasErrors: false },
        { routeName: 'HumanActivity', label: this.$t('Human Activities'), isRequired: false, hasErrors: false },
        { routeName: 'StormDamage', label: this.$t('Storm Damage'), isRequired: false, hasErrors: false }
      ]
    }
  },
  computed: {
    ...mapGetters("auth", ["user"])
  },
  methods: {
    routeTo(routeName) {
      this.$router.push({ name: routeName })
    }
  }
}
</script>

<style scoped>

</style>
