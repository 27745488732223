import {SubmissionsService} from "@/services/submissions.service"
import {AuthenticationError, AuthService} from "@/services/auth.service";

const state = {
    submissions: {}
}

const getters = {
    submissions: (state: { submissions: any }) => {
        return state.submissions;
    }
}

const actions = {
    async setSubmissions(context: any, data: any) {
        return new Promise((resolve, reject) => {
            SubmissionsService.setSubmissions(data)
                .then((res: any) => {
                    context.commit("submissionsSuccess", res);
                    console.log('actions.setSubmissions.res', res)
                    resolve(res)
                })
                .catch((err: any) => {
                    console.log('actions.setSubmissions.err', err)
                    reject(err)
                })
        })
    },

    async getSubmissions(context: any) {
        return new Promise((resolve, reject) => {
            SubmissionsService.getSubmissions()
                .then((res: any) => {
                    console.log('actions.getSubmissions.res', res)
                    resolve(res);
                })
                .catch((err: any) => {
                    console.log('actions.getSubmissions.err', err)
                    reject(err);
                });
        });
    },
}

const mutations = {
    submissionsSuccess(state: {
        submissions: any;
    }, submissions: any) {
        state.submissions = submissions
    },
}

export const submissions = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
