<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>clock</title>
    <path d="M16 0c-8.823 0-16 7.177-16 16s7.177 16 16 16 16-7.177 16-16-7.177-16-16-16zM23.609 24.276c-0.26 0.26-0.601 0.391-0.943 0.391s-0.683-0.131-0.943-0.391l-6.667-6.666c-0.251-0.249-0.391-0.588-0.391-0.943v-8.667c0-0.737 0.597-1.333 1.333-1.333s1.333 0.596 1.333 1.333v8.115l6.276 6.276c0.521 0.521 0.521 1.364 0 1.885z"></path>
  </svg>
</template>

<script>
export default {
  name: "Time"
}
</script>

<style scoped>

</style>
