<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>right-arrow</title>
    <path d="M16.65 9.055c-0.271-0.272-0.712-0.272-0.984 0s-0.272 0.712 0 0.984l5.268 5.268h-13.996c-0.385 0-0.698 0.313-0.698 0.698s0.313 0.698 0.698 0.698h13.99l-5.268 5.268c-0.131 0.13-0.204 0.307-0.204 0.492s0.073 0.361 0.204 0.492c0.131 0.132 0.31 0.205 0.495 0.202 0.185-0.001 0.363-0.073 0.495-0.202l6.454-6.454c0.131-0.132 0.205-0.31 0.203-0.496s-0.074-0.362-0.203-0.495z"></path>
    <path d="M16.009 1.003c-6.067-0.001-11.538 3.654-13.86 9.259s-1.037 12.058 3.254 16.347c5.857 5.857 15.354 5.857 21.211 0s5.857-15.353 0-21.211c-2.807-2.822-6.625-4.405-10.606-4.396zM16.009 29.61c-7.501 0-13.606-6.105-13.606-13.606s6.105-13.606 13.606-13.606 13.606 6.105 13.606 13.606-6.105 13.606-13.606 13.606z"></path>
  </svg>
</template>

<script>
export default {
  name: "RightArrow"
}
</script>

<style scoped>

</style>
