<template>
  <ion-card class="submission-card" :class="{active: item.isRequired, 'has-errors': item.hasErrors }">
    <ion-card-header>
      <ion-card-title>
        {{ item.label }}
      </ion-card-title>
    </ion-card-header>

    <CustomShape class="card-custom-shape"></CustomShape>
    <WavesShape class="card-waves"></WavesShape>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle } from '@ionic/vue'
import CustomShape from "@/components/icons/CustomShape.vue"
import WavesShape from "@/components/icons/WavesShape.vue"
import "@/theme/components/submission-card.scss"

export default {
  name: "SubmissionCard",

  components: { IonCard, IonCardHeader, IonCardTitle, CustomShape, WavesShape },

  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    }
  }
}
</script>

<style scoped>

</style>
