<template>
  <DefaultLayout>
      <template v-slot:header>
        <CoastHeader :hasBackButton="false" :title="$t('Account')"></CoastHeader>
      </template>
      <form @submit.prevent="handleReset" autocomplete="off">
        <ion-list lines="none">
          <ion-item lines="none">
            <ion-label class="coast-label" position="stacked">{{ $t('First Name') }}</ion-label>
            <ion-input type="text" inputmode="text" v-model="form.first_name" id="first_name"
                       :placeholder="user.first_name" autocomplete="off" @ionChange="handleChange" required></ion-input>
          </ion-item>

          <ion-item lines="none">
            <ion-label class="coast-label" position="stacked">{{ $t('Last Name') }}</ion-label>
            <ion-input type="text" inputmode="text" v-model="form.last_name" id="last_name"
                       :placeholder="user.last_name" autocomplete="off" @ionChange="handleChange" required></ion-input>
          </ion-item>

          <ion-item lines="inset">
            <ion-label class="coast-label" position="stacked">{{ $t('Coast Watcher ID') }}</ion-label>
            <div class="id">{{user.id}}</div>
          </ion-item>

          <ion-item lines="none">
            <ion-label class="coast-label" position="stacked">{{ $t('Phone number') }}</ion-label>
            <ion-input type="tel" inputmode="tel" v-model="form.phone" id="phone" :placeholder="user.phone_number"
                       autocomplete="off" @ionChange="handleChange" required></ion-input>
          </ion-item>

          <ion-item lines="inset">
            <ion-label class="coast-label" position="stacked">{{ $t('Coast Watcher ID') }}</ion-label>
            <div class="id">{{user.email}}</div>
          </ion-item>

          <ion-item lines="none" class="password-input">
            <ion-label class="coast-label" position="stacked">{{ $t('Password') }}</ion-label>
            <ion-input :type="showPassword ? 'text' : 'password'" inputmode="password" v-model="form.password"
                       id="password" :placeholder="$t('Password')" autocomplete="off" @ionChange="handleChange" required></ion-input>
          </ion-item>
        </ion-list>
      </form>

      <ion-item lines="none">
        <ion-button size="small" color="dark" @click="handleSignOut">Logout</ion-button>
        <ion-button v-if="!!Object.values(form).length" size="small" color="success" @click="handleSubmit">Save Changes</ion-button>
      </ion-item>
    </DefaultLayout>
</template>

<script>
import {eye, eyeOff} from 'ionicons/icons';
import CoastHeader from "@/components/CoastHeader.vue"
import DefaultLayout from "@/views/layouts/default.vue"
import {mapGetters} from "vuex";
import {IonButton, IonInput} from "@ionic/vue";
import ApiService from "@/services/api.service";

export default {
  name: "Account",
  components: { CoastHeader, DefaultLayout, IonInput, IonButton},

  data() {
    return {
      eye,
      eyeOff,
      form: {},
      showPassword: false
    }
  },

  computed: {
    ...mapGetters("auth", ["user"])
  },

  methods: {
    handleReset() {
      this.form = {}
    },

    toggleShow(variable) {
      this[variable] = !this[variable]
    },

    handleSubmit() {
      ApiService.put(`client/user/${this.user.id}`, this.form)
        .then(data => {
          console.log(data)
          this.form = {}
          this.$store.dispatch('auth/getUser')
        })
      this.handleReset()
    },

    handleSignOut() {
      this.$store.dispatch('auth/signOut')
          .then(() => this.$router.replace({name: 'Login'}))
    },
    handleChange(e) {
      if (!e.target.value) {
        delete this.form[e.target.id]
      }
    }
  }
}
</script>

<style scoped>

</style>
