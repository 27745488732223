<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>shape</title>
    <path d="M0.16 12.903c-0.257-2.857 1.034-5.040 2.886-7.017 1.802-1.865 4.083-3.256 6.644-3.975l0.104-0.025c1.597-0.476 3.433-0.75 5.332-0.75 0.579 0 1.152 0.025 1.718 0.075l-0.073-0.005c3.825 0.292 7.356 1.266 10.567 2.803l-0.178-0.077c2.535 1.168 4.366 3.485 4.839 6.264l0.007 0.050c0.406 2.097-0.143 4.057-1.109 5.931-1.2 2.228-2.689 4.129-4.447 5.74l-0.016 0.014c-1.872 1.736-3.909 3.366-6.059 4.843l-0.181 0.117c-1.723 1.257-3.699 2.327-5.812 3.116l-0.17 0.056c-2.474 0.851-4.994 1.143-7.429-0.137-1.642-0.93-2.942-2.298-3.764-3.946l-0.024-0.054c-1.155-2.131-1.978-4.616-2.32-7.252l-0.011-0.108c-0.24-1.874-0.44-3.754-0.663-5.629z"></path>
  </svg>
</template>

<script>
export default {
  name: "CustomShape"
}
</script>

<style scoped>

</style>
