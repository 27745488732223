<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>sea</title>
    <path d="M30.952 21.385c-1.131 0-2.204-0.493-2.945-1.352-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.352-2.945 1.352s-2.204-0.493-2.945-1.352c-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.352-2.945 1.352s-2.204-0.493-2.945-1.352c-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.352-2.945 1.352s-2.204-0.493-2.945-1.352c-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.352-2.945 1.352-0.579 0-1.047 0.469-1.047 1.047s0.469 1.047 1.047 1.047c1.218 0 2.393-0.372 3.378-1.047 0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047s2.393-0.372 3.378-1.047c0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047s2.393-0.372 3.378-1.047c0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047s2.393-0.372 3.378-1.047c0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047 0.579 0 1.047-0.469 1.047-1.047s-0.469-1.047-1.048-1.047z"></path>
    <path d="M30.952 15.81c-1.131 0-2.204-0.493-2.945-1.352-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.351-2.945 1.351s-2.204-0.493-2.945-1.352c-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.351-2.945 1.351s-2.204-0.493-2.945-1.351c-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.352-2.945 1.352s-2.204-0.493-2.945-1.351c-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.352-2.945 1.352-0.579 0-1.047 0.469-1.047 1.047s0.469 1.047 1.047 1.047c1.218 0 2.393-0.372 3.378-1.047 0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047s2.393-0.372 3.378-1.047c0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047s2.393-0.372 3.378-1.047c0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047s2.393-0.372 3.378-1.047c0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047 0.579 0 1.047-0.469 1.047-1.047s-0.469-1.047-1.048-1.047z"></path>
    <path d="M1.047 12.33c1.218 0 2.393-0.372 3.378-1.047 0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047s2.393-0.372 3.378-1.047c0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047s2.393-0.372 3.378-1.047c0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047s2.393-0.372 3.378-1.047c0.123-0.084 0.243-0.172 0.36-0.266 0.117 0.093 0.237 0.182 0.36 0.266 0.986 0.676 2.16 1.047 3.378 1.047 0.579 0 1.047-0.469 1.047-1.047s-0.469-1.047-1.047-1.047c-1.131 0-2.204-0.493-2.945-1.352-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.351-2.945 1.351s-2.204-0.493-2.945-1.352c-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.351-2.945 1.351s-2.204-0.493-2.945-1.351c-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.352-2.945 1.352s-2.204-0.492-2.945-1.351c-0.199-0.231-0.489-0.363-0.793-0.363s-0.594 0.133-0.793 0.363c-0.741 0.859-1.814 1.351-2.945 1.351-0.579 0-1.047 0.469-1.047 1.047s0.469 1.047 1.047 1.047z"></path>
  </svg>
</template>

<script>
export default {
  name: "WavesShape"
}
</script>

<style scoped>

</style>
